import { Fragment, useContext, useState } from "react";
import Header from "../../components/Home/Header";
import Footer from "../../components/Home/Footer";
import { useNavigate } from "react-router-dom";
import firebase from "../../helpers/base";
import Input from "../../UI/Input/Input";
import {
  emailValidator,
  multiValidator,
  requiredValidator,
} from "../../helpers/form-validators";
import Button from "../../UI/Button/Button";
import SnackbarContext from "../../contexts/SnackbarContext/snackbar-context";

const ForgotPassword = (props) => {
  const snackbarContext = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [emailState, setEmailState] = useState({
    value: "",
    isValid: false,
  });
  const handleReset = async (event) => {
    event.preventDefault();
    try {
      if (emailState.value != "jdnic07@gmail.com") {
        snackbarContext.showSnackbar("error", "Invalid user");
        return;
      }
      await firebase.auth().sendPasswordResetEmail(emailState.value);
      snackbarContext.showSnackbar(
        "success",
        `Reset details sent to ${emailState.value}!`
      );
    } catch (error) {
      snackbarContext.showSnackbar("error", error.toString());
    }
  };

  return (
    <Fragment>
      <Header />
      <section id="contact" className="contact" style={{ paddingTop: "10%" }}>
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Password Reset</h2>
          </div>

          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <div
              className="col-lg-3 col-md-3"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <br />
              <br />
              <br />
              <Input
                type="text"
                onChange={(value, isValid) => {
                  setEmailState({ value, isValid });
                }}
                placeholder={"Email"}
                validator={(value) =>
                  multiValidator([
                    emailValidator(value, "Invalid email"),
                    requiredValidator(value, "Required"),
                  ])
                }
              />
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "20px",
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/login")}
              >
                Sign in?
              </div>

              <br />
              <Button
                title={"Reset"}
                onClick={handleReset}
                isDisabled={!emailState.isValid}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default ForgotPassword;
