import { Fragment, useCallback, useContext, useState } from "react";
import Header from "../../components/Home/Header";
import Footer from "../../components/Home/Footer";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import firebase from "../../helpers/base";
import Input from "../../UI/Input/Input";
import {
  emailValidator,
  multiValidator,
  requiredValidator,
} from "../../helpers/form-validators";
import Button from "../../UI/Button/Button";
import { AuthContext } from "../../contexts/AuthContext/auth-context";
import SnackbarContext from "../../contexts/SnackbarContext/snackbar-context";

const Login = (props) => {
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);
  const [emailState, setEmailState] = useState({
    value: "",
    isValid: false,
  });
  const [passwordState, setPasswordState] = useState({
    value: "",
    isValid: false,
  });
  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(emailState.value, passwordState.value);
      navigate("/admin");
      snackbarContext.showSnackbar("success", "Login Successful!");
    } catch (error) {
      snackbarContext.showSnackbar("error", error.toString());
    }
  };

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Navigate to="/" />;
  }
  return (
    <Fragment>
      <Header />
      <section id="contact" className="contact" style={{ paddingTop: "10%" }}>
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Admin Login</h2>
          </div>

          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <div
              className="col-lg-3 col-md-3"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <br />
              <br />
              <br />
              <Input
                type="text"
                onChange={(value, isValid) => {
                  setEmailState({ value, isValid });
                }}
                placeholder={"Email"}
                validator={(value) =>
                  multiValidator([
                    emailValidator(value, "Invalid email"),
                    requiredValidator(value, "Required"),
                  ])
                }
              />

              <Input
                type="password"
                onChange={(value, isValid) => {
                  setPasswordState({ value, isValid });
                }}
                placeholder={"Password"}
                validator={(value) => requiredValidator(value, "Required")}
              />
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "20px",
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/forgot-password")}
              >
                Forgot password?
              </div>

              <br />
              <Button
                title={"Login"}
                onClick={handleLogin}
                isDisabled={!(emailState.isValid && passwordState.isValid)}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Login;
