import { useNavigate } from "react-router-dom";
import imgJackie from "../../../assets/img/jackie.png";
import imgBritt from "../../../assets/img/britt.jpg";
import imgAryanna from "../../../assets/img/aryanna.png";
import imgAndreas from "../../../assets/img/andreas.jpg";
import imgLauren from "../../../assets/img/lauren.png";
import imgJen from "../../../assets/img/jen.png";
import imgRach from "../../../assets/img/rach.png";
import imgAndre from "../../../assets/img/andre.jpg";

const images = {
  IrxkW94AGye9y5FPqmsZ: imgAndreas,
  I8sOaPGv0zuBMSkt5drF: imgAryanna,
  JKHgjstS9TSSXGvX02F3: imgBritt,
  TnC7XT5uGU1ViEXdjY78: imgJackie,
  "5AnNmCew584FgUDM1rNN": imgJen,
  "0ysRaHTeFFajAw1rHqlB": imgLauren,
  GcTj31vBzOZLlYfnU1SI: imgRach,
  U0QyQpsEbNmMM2IVAQ95: imgAndre,
};

const OurTeamItem = (props) => {
  const memberData = props.memberData;
  const navigate = useNavigate();

  const memberStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    background: "#fff",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    transition: "transform 0.3s ease-in-out",
  };

  const memberImgStyle = {
    width: "100%",
    height: "auto",
    flexShrink: 0,
  };

  const memberInfoStyle = {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    textAlign: "center",
  };

  const memberInfoH4Style = {
    fontSize: "18px",
    fontWeight: 700,
    margin: 0,
    padding: 0,
  };

  const memberInfoSpanStyle = {
    display: "block",
    fontSize: "13px",
    color: "#777",
    marginTop: "5px",
  };

  return (
    <div
      className="col-lg-3 col-md-6 d-flex align-items-stretch"
      style={{ marginBottom: "20px" }}
      onClick={() =>
        navigate(`team-details?teamMemberId=${props.memberData.teamMemberId}`)
      }
    >
      <div
        className="member"
        style={memberStyle}
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div className="member-img" style={memberImgStyle}>
          <img
            src={images[props.memberData.teamMemberId]}
            className="img-fluid"
            alt={props.memberData.teamMemberId}
          />
        </div>

        <div className="member-info" style={memberInfoStyle}>
          <h4 style={memberInfoH4Style}>{memberData.name}</h4>
          <span style={memberInfoSpanStyle}>{memberData.title}</span>
        </div>
      </div>
    </div>
  );
};

export default OurTeamItem;
