import { Fragment, useState } from "react";
import Footer from "../../components/Home/Footer";

import { Box } from "@mui/material";
import AdminHeader from "../../components/Admin/AdminHeader";
import { NavLink } from "react-router-dom";

const InitialRegistration = (props) => {
  return (
    <Fragment>
      <AdminHeader />
      <section id="contact" className="contact" style={{ paddingTop: "10%" }}>
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Registration</h2>
          </div>

          <div
            className="col-lg-12 col-md-12"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <br />
            <br />
            <br />
            <div className="row" data-aos="fade-up" data-aos-delay="300">
              <div
                className="col-lg-6 col-md-6"
                style={{ padding: "5px 10px", textAlign: "center" }}
              >
                <NavLink to="/authenticate-token">
                  <div className="icon-box">
                    <Box sx={{ width: 20 }} />
                    <h3 style={{ color: "black" }}>Start New Registration</h3>
                  </div>
                </NavLink>
              </div>
              <div
                className="col-lg-6 col-md-6"
                style={{ padding: "5px 10px", textAlign: "center" }}
              >
                <NavLink to="/authenticate-token">
                  <div className="icon-box">
                    <Box sx={{ width: 20 }} />
                    <h3 style={{ color: "black" }}>Start New Registration</h3>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default InitialRegistration;
