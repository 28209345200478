import Button from "../../../UI/Button/Button";
import { Fragment, useContext, useEffect, useState } from "react";
import AdminHeader from "../../../components/Admin/AdminHeader";
import Footer from "../../../components/Home/Footer";
import MyTable from "../../../UI/ReorderableGrid/ReorderableGrid";
import DataContext from "../../../contexts/DataContext/data-context";
import useHttp from "../../../hooks/use-http";
import ServiceDetailForm from "./ServiceDetailForm";

const ServiceDetails = (props) => {
  const dataContext = useContext(DataContext);
  const [
    isServicesDataLoading,
    servicesDataError,
    getServicesData,
    servicesData,
  ] = useHttp();
  const [service, setService] = useState({});
  const handleEdit = (service) => {
    setService(service);
  };
  useEffect(() => {
    if (dataContext.services.length < 1) {
      getServicesData(
        {
          url: `${process.env.REACT_APP_BASE_API_URL}/website/services/getAll`,
        },
        (data) => dataContext.setServices(data)
      );
    }
  }, []);

  return (
    <Fragment>
      <AdminHeader />
      <section id="contact" className="contact" style={{ paddingTop: "10%" }}>
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Service Details</h2>
          </div>
          <ServiceDetailForm service={service} />
          <MyTable
            services={dataContext.services}
            onEdit={handleEdit}
          ></MyTable>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default ServiceDetails;
