import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "tdta-b5e27.firebaseapp.com",
  projectId: "tdta-b5e27",
  storageBucket: "tdta-b5e27.appspot.com",
  messagingSenderId: "747616930192",
  appId: "1:747616930192:web:40ed4a92279c39538c4b15",
  measurementId: "G-ET1HSNSNRF",
};

firebase.initializeApp(config);

export default firebase;
