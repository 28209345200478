import { Fragment } from "react";
import "bootstrap/dist/css/bootstrap.css";
import bgimg from "../../assets/img/main.jpg";
import hoverimg from "../../assets/img/topdog.png";
import { Box } from "@mui/material";
import { NavLink } from "react-router-dom";

const Hero = (props) => {
  return (
    <Fragment>
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <div className="col-lg-8">
                <h1 data-aos="fade-up">Top Dog Training Academy</h1>
              </div>
              <h4 data-aos="fade-up" data-aos-delay="400">
                Certified Dog trainer in Whitehorse, Yukon.
              </h4>
              <Box sx={{ height: 60 }}></Box>
              <div data-aos="fade-up" data-aos-delay="800">
                <NavLink to="/authenticate-token" className="btn-get-started">
                  Register
                </NavLink>
              </div>
            </div>
            <div
              className="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <img src={hoverimg} className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
      </section>
      <img src={bgimg} alt="" style={{ maxWidth: "100%", height: "auto" }} />
    </Fragment>
  );
};

export default Hero;
