const requiredValidator = (value, errorText) => {
  if (!value) {
    return [false, errorText];
  }
  if (value === "") {
    return [false, errorText];
  }
  return [true, ""];
};

const minLengthValidator = (value, length, errorText) => {
  if (!value) {
    return [false, errorText];
  }
  if (value.length < length) {
    return [false, errorText];
  }
  return [true, ""];
};

const emailValidator = (email, errorText) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email) === false
    ? [emailRegex.test(email), errorText]
    : [true, ""];
};

const dateValidator = (dateString, errorText) => {
  // First check for the pattern
  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) return [false, errorText];

  // Parse the date parts to integers
  const parts = dateString.split("-");
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12)
    return [false, errorText];

  // Check the number of days in the month
  const daysInMonth = new Date(year, month, 0).getDate();
  if (day === 0 || day > daysInMonth) return [false, errorText];

  // Return true if the date is valid
  return [true, ""];
};

const multiValidator = (validators) => {
  let isValidated = [true, ""];
  validators.forEach((validator) => {
    if (validator[0] === false) {
      isValidated = [false, validator[1]];
    }
  });
  return isValidated;
};

const phoneFormatter = (value, previousValue) => {
  // return nothing if no value
  if (!value) return value;

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};

const phoneValidator = (value) => {
  if (value.length !== 10) return [false, "Invalid phone number"];
  return [true, ""];
};

module.exports = {
  requiredValidator,
  minLengthValidator,
  dateValidator,
  multiValidator,
  emailValidator,
  phoneValidator,
};
