const Gallery = (props) => {
  return (
    <section id="gallery" className="parallax section">
      <div className="wrapsection">
        <div
          className="parallax-overlay"
          style={{ backgroundColor: "#00c1c1", opacity: 0.9 }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 sol-sm-12">
              <div className="section-title" data-aos="fade-up">
                <h2>Gallery</h2>
                <br />
                <br />
                <br />
                <div
                  className="embedsocial-hashtag"
                  data-ref="a5f79b6261209c0b615679281c9d3367afb0a5c2"
                >
                  <a
                    className="feed-powered-by-es"
                    href="https://embedsocial.com/products/embedfeed/"
                    target="_blank"
                    rel="noreferrer"
                    title="Powered by EmbedSocial"
                  >
                    Powered by EmbedSocial<span>→</span>
                  </a>
                </div>
                {(function (d, s, id) {
                  var js;
                  if (d.getElementById(id)) {
                    return;
                  }
                  js = d.createElement(s);
                  js.id = id;
                  js.src = "https://embedsocial.com/cdn/ht.js";
                  d.getElementsByTagName("head")[0].appendChild(js);
                })(document, "script", "EmbedSocialHashtagScript")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
