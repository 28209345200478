import { Fragment } from "react";
import Header from "../../components/Home/Header";
import Footer from "../../components/Home/Footer";
import errorImg from "../../assets/img/404.png";

const NotFound = (props) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const success = queryParameters.get("success");
  return (
    <Fragment>
      <Header />
      <section id="contact" className="contact" style={{ paddingTop: "10%" }}>
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Page Not Found</h2>
          </div>

          <div
            style={{ textAlign: "center" }}
            className="col-lg-12 col-md-12"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div>
              <img src={errorImg} alt="" style={{ height: 400 }} />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Fragment>
  );
};

export default NotFound;
