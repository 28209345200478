import "../../../assets/css/style.css";
import useHttp from "../../../hooks/use-http";
import { useContext, useEffect } from "react";
import DataContext from "../../../contexts/DataContext/data-context";
import ServiceItem from "./ServiceItem";
import LoadingIndicator from "../../../UI/LoadingIndicator/LoadingIndicator";

const Services = (props) => {
  const dataContext = useContext(DataContext);
  const [
    isServicesDataLoading,
    servicesDataError,
    getServicesData,
    servicesData,
  ] = useHttp();
  useEffect(() => {
    if (dataContext.services.length < 1) {
      getServicesData(
        {
          url: `${process.env.REACT_APP_BASE_API_URL}/website/services/getAll?pageNumber=1&showDisabled=true`,
        },
        (data) => dataContext.setServices(data)
      );
    }
  }, []);
  return (
    <section id="services" className="services">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Services</h2>
        </div>
        {isServicesDataLoading && <LoadingIndicator />}
        {dataContext.services.map((service) => {
          if (!service) {
            return null;
          }
          if (service.isDisabled === true) {
            return null;
          } else {
            return <ServiceItem key={service.serviceId} data={service} />;
          }
        })}
      </div>
    </section>
  );
};

export default Services;
