import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import "bootstrap/dist/css/bootstrap.min.css";
import { useContext, useState } from "react";
import Button from "../Button/Button";
import DataContext from "../../contexts/DataContext/data-context";
import useHttp from "../../hooks/use-http";
import SnackbarContext from "../../contexts/SnackbarContext/snackbar-context";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

export default function ReorderableGrid(props) {
  const dataContext = useContext(DataContext);
  const snackbarContext = useContext(SnackbarContext);
  const [isUpdatePriorityEnabled, toggleUpdatePriority] = useState(false);
  const services = props.services;
  const handleEdit = (service) => {
    props.onEdit(service);
  };
  const [
    isServicesDataLoading,
    servicesDataError,
    getServicesData,
    servicesData,
  ] = useHttp();
  const [
    isServicesPriorityDataLoading,
    servicesPriorityDataError,
    setServicesPriorityData,
    servicesPriorityData,
  ] = useHttp();
  const handleUpdatePriority = () => {
    const body = dataContext.services;
    setServicesPriorityData(
      {
        url: `${process.env.REACT_APP_BASE_API_URL}/website/services/updatePriority`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      },
      (data) => {
        getServicesData(
          {
            url: `${process.env.REACT_APP_BASE_API_URL}/website/services/getAll?pageNumber=1&showDisabled=true`,
          },
          (data) => {
            dataContext.setServices(data);
            snackbarContext.showSnackbar("success", "Priority updated!");
          }
        );
      }
    );
  };
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = props.services;
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    tempData.map((element, index) => {
      element.service_priority = index + 1;
    });
    toggleUpdatePriority(true);
    dataContext.setServices(tempData);
  };
  return (
    <div className="App mt-4 row">
      <div className="col-lg-9 col-md-8 col-sm-6">
        {(isServicesDataLoading || isServicesPriorityDataLoading) && (
          <LoadingIndicator />
        )}
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6">
        {" "}
        <Button
          title="Update Priority"
          isDisabled={!isUpdatePriorityEnabled}
          onClick={() => handleUpdatePriority()}
        ></Button>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <table className="table borderd">
          <thead>
            <tr>
              <th>Reorder</th>
              <th>Priority</th>
              <th>Service Name</th>
              <th>Duration</th>
              <th>Price</th>
              <th>Description</th>
              <th>Status</th>
              <th>Edit</th>
            </tr>
          </thead>
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <tbody
                className="text-capitalize"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {services?.map((service, index) => (
                  <Draggable
                    key={service.serviceId.toString()}
                    draggableId={service.serviceId.toString()}
                    index={index}
                  >
                    {(provider) => (
                      <tr {...provider.draggableProps} ref={provider.innerRef}>
                        <td {...provider.dragHandleProps}> = </td>
                        <td>{service.service_priority}</td>
                        <td>{service.service_name}</td>
                        <td>{service.service_time}</td>
                        <td>{service.service_price}</td>
                        <td>{service.service_desc}</td>
                        <td>
                          {service.isDisabled === true ? (
                            <Button title="Disabled" color="orange" />
                          ) : (
                            <Button title="Active" color={"green"} />
                          )}
                        </td>
                        <td>
                          <Button
                            title="Edit"
                            onClick={() => {
                              handleEdit(service);
                            }}
                          />
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
    </div>
  );
}
