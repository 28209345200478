import React, { useContext, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../UI/Button/Button";
import Input from "../../UI/Input/Input";
import {
  emailValidator,
  multiValidator,
  requiredValidator,
} from "../../helpers/form-validators";
import SnackbarContext from "../../contexts/SnackbarContext/snackbar-context";
import useHttp from "../../hooks/use-http";

const Contact = (props) => {
  const initialFormState = {
    contactName: { value: "", isValid: false },
    contactEmail: { value: "", isValid: false },
    contactSubject: { value: "", isValid: false },
    contactMessage: { value: "", isValid: false },
  };
  const [formState, setFormState] = useState(initialFormState);
  const [captchaValue, setCaptchaValue] = useState();
  const snackbarContext = useContext(SnackbarContext);

  const handleCaptchaChange = (value) => {
    // Called when the user successfully completes the CAPTCHA
    setCaptchaValue(value);
  };

  const mapObject = (inputObj) => {
    const mappedObj = {};
    for (const key in inputObj) {
      if (Object.hasOwnProperty.call(inputObj, key)) {
        const element = inputObj[key];
        mappedObj[key] = element.value;
      }
    }
    return mappedObj;
  };
  const [isLoading, contactError, setContact, contactData] = useHttp();

  const isFormValid =
    formState.contactEmail.isValid &&
    formState.contactMessage.isValid &&
    formState.contactName.isValid &&
    formState.contactSubject.isValid;

  const clickHandler = (e) => {
    e.preventDefault();
    snackbarContext.showSnackbar("error", "This is an error!");
    // Check if the captcha value is not empty before submitting the form
    if (!captchaValue) {
      alert("Please verify the CAPTCHA.");
    }
    if (isFormValid) {
      setContact(
        {
          url: `${process.env.REACT_APP_BASE_API_URL}/website/contacts/create`,
          body: mapObject(formState),
          headers: { "Content-Type": "application/json" },
          method: "POST",
        },
        (data) => {
          snackbarContext.showSnackbar("success", "Contact request sent!");
          setFormState(initialFormState);
        }
      );
    } else {
    }
  };
  return (
    <section id="contact" className="contact">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Contact Us</h2>
        </div>

        <div className="row">
          <div
            className="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="contact-about">
              <h3>Top Dog Training Academy</h3>
              <p>
                Top Dog Training Academy has helped thousands of dogs and owners
                in the Yukon and across Canada remotely to achieve their
                training goals.
              </p>
              <div className="social-links">
                <a
                  href="https://www.facebook.com/TopDogTrainingAcademy"
                  className="facebook"
                >
                  <i className="icofont-facebook"></i>
                </a>
                <a
                  href="https://www.instagram.com/top_dog_training_academy/"
                  className="instagram"
                >
                  <i className="icofont-instagram"></i>
                </a>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6 mt-4 mt-md-0"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="info">
              <div>
                <i className="ri-map-pin-line"></i>
                <p>
                  129 Copper Road (925.92 mi)
                  <br />
                  Whitehorse, Yukon
                </p>
              </div>

              <div>
                <i className="ri-mail-send-line"></i>
                <p>jdnic07@gmail.com</p>
              </div>

              <div>
                <i className="ri-phone-line"></i>
                <p>+1 (867) 334-7924</p>
              </div>
            </div>
          </div>

          <div
            className="col-lg-5 col-md-12"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <form>
              <input type="hidden" name="_captcha" value="true" />
              <input type="hidden" name="_template" value="box" />
              <Input
                placeholder={"Your Name"}
                type={"text"}
                initialValue={formState.contactName.value}
                validator={(value) => {
                  return requiredValidator(value, "Required");
                }}
                onChange={(value, isValid) => {
                  setFormState((prevState) => {
                    return {
                      ...prevState,
                      contactName: { value: value, isValid: isValid },
                    };
                  });
                }}
              ></Input>
              <Input
                placeholder={"Your Email"}
                type={"text"}
                initialValue={formState.contactEmail.value}
                validator={(value) => {
                  return multiValidator([
                    emailValidator(value, "Invalid Email"),
                    requiredValidator(value, "Required"),
                  ]);
                }}
                onChange={(value, isValid) => {
                  setFormState((prevState) => {
                    return {
                      ...prevState,
                      contactEmail: { value: value, isValid: isValid },
                    };
                  });
                }}
              ></Input>
              <Input
                placeholder={"Subject"}
                type={"text"}
                initialValue={formState.contactSubject.value}
                validator={(value) => {
                  return requiredValidator(value, "Required");
                }}
                onChange={(value, isValid) => {
                  setFormState((prevState) => {
                    return {
                      ...prevState,
                      contactSubject: { value: value, isValid: isValid },
                    };
                  });
                }}
              ></Input>
              <Input
                placeholder={"Message"}
                type={"text"}
                initialValue={formState.contactMessage.value}
                isMultiline={true}
                validator={(value) => {
                  return requiredValidator(value, "Required");
                }}
                onChange={(value, isValid) => {
                  setFormState((prevState) => {
                    return {
                      ...prevState,
                      contactMessage: { value: value, isValid: isValid },
                    };
                  });
                }}
              ></Input>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA}
                  onChange={handleCaptchaChange}
                />
                {isLoading ? (
                  <div>Loading..</div>
                ) : (
                  <Button
                    title={"Send"}
                    onClick={clickHandler}
                    isDisabled={!isFormValid || !captchaValue}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
