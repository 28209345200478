import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Home/Header";
import Footer from "../../components/Home/Footer";
import Input from "../../UI/Input/Input";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Button from "../../UI/Button/Button";
import RegFormContext from "../../contexts/RegFormContext/reg-form-context";
import {
  dateValidator,
  emailValidator,
  multiValidator,
  phoneValidator,
  requiredValidator,
} from "../../helpers/form-validators";
import axios from "axios";
import useHttp from "../../hooks/use-http";
import { mapObject } from "../../helpers/general";
import LoadingIndicator from "../../UI/LoadingIndicator/LoadingIndicator";

const RegistrationDetails = (props) => {
  const regFormContext = useContext(RegFormContext);
  const navigate = useNavigate();
  const formatVacDate = () => {
    // Create a new Date object
    const today = new Date();

    // Get the year, month, and day from the date object
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month starts from 0, so adding 1 and padding with zero if needed
    const day = String(today.getDate()).padStart(2, "0"); // Pad day with zero if needed

    // Format the date as yyyy-mm-dd
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate; // Output: yyyy-mm-dd format of the current date
  };
  const [isServiceLoading, serviceError, getServiceData, serviceData] =
    useHttp();
  const fetchServiceData = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const serviceId = urlParams.get("serviceId");
    if (serviceId) {
      getServiceData(
        {
          url: `${process.env.REACT_APP_BASE_API_URL}/website/services/get?serviceId=${serviceId}`,
        },
        (data) => {}
      );
    }
  };
  useEffect(() => {
    fetchServiceData();
  }, []);
  const [isLoading, setIsLoading] = useState(false); // state to handle loading status
  const formSubmitHandler = async () => {
    if (regFormContext.isFormValid && regFormContext.isFormTouched) {
      setIsLoading(true); // Set loading to true before making the HTTP request
      try {
        const body = mapObject(regFormContext.form);
        body.program = serviceData.service_name;
        body.serviceId = serviceData.serviceId;
        body.payment = { status: "awaiting payment", date: new Date() };
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_API_URL}/website/registrations/create`,
          body
        );
        navigate(`/complete-payment?regId=${response.data}`); // Navigate to "/home" upon successful submission
      } catch (error) {
        console.error("Error:", error);
        // Handle error response here if necessary
      } finally {
        setIsLoading(false); // Set loading back to false after the request is complete
      }
    }
  };
  return (
    <Fragment>
      <Header />
      <section id="contact" className="contact">
        <br />
        <br />
        <br />
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Register</h2>
          </div>
          <p style={{ color: "red", textAlign: "center", fontWeight: "bold" }}>
            All fields are required. Please fill "NA" in the ones that dont
            apply
          </p>
          <br />

          {serviceData && (
            <div className="row">
              <div
                className="col-md-12"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="col-md-12">
                  <h4 style={{ color: "black", textAlign: "center" }}>
                    Program Name: {serviceData.service_name}
                  </h4>
                  <br />
                  <br />
                </div>

                <FormControl style={{ width: "100%" }}>
                  <br />

                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        placeholder="Your Name"
                        type="text"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("name", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        placeholder="Dog's Name"
                        errorText="Required"
                        type="text"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("dogName", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        placeholder="Phone Number"
                        errorText="Required"
                        type="number"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                            phoneValidator(value),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("phone", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        placeholder="Your Email"
                        errorText="Required"
                        type="email"
                        validator={(value) => {
                          return multiValidator([
                            emailValidator(value, "Invalid email"),
                            requiredValidator(value, "Reqiured"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("email", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        placeholder="Breed"
                        errorText="Required"
                        type="text"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("breed", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-2">
                      <Input
                        placeholder="Dog's Sex"
                        errorText="Required"
                        type="text"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("sex", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-2">
                      <Input
                        placeholder="Dog's Age"
                        errorText="Required"
                        type="text"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("age", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-2">
                      <Input
                        placeholder="Spay/Neutered"
                        errorText="Required"
                        type="text"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("spay", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        placeholder="Vet Clinic"
                        errorText="Required"
                        type="text"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("vet", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        label={"Date of last vaccinations/or titer test"}
                        placeholder="Date of last vaccinations/or titer test"
                        errorText="Required"
                        initialValue={formatVacDate()}
                        type="date"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                            dateValidator(value, "Invalid Date"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("vacDate", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-2">
                      <Input
                        placeholder="Dog's Weight (Kgs)"
                        errorText="Required"
                        type="number"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("dogWeight", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />

                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        placeholder="Medical or mobility issues"
                        errorText="Required"
                        type="text"
                        isMultiline="true"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("medIssues", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        placeholder="Previous health issues or injury"
                        errorText="Required"
                        type="text"
                        isMultiline="true"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("healthIssues", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        placeholder="Any fears"
                        errorText="Required"
                        type="text"
                        isMultiline="true"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("fears", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        placeholder="Any previous training (if so with who)"
                        errorText="Required"
                        type="text"
                        isMultiline="true"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("previousTraining", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        placeholder="What skills does your dog have now"
                        errorText="Required"
                        type="text"
                        isMultiline="true"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("skills", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        placeholder="Has your dog been in a dog fight or been attacked by another dog? Has your dog bit a human?"
                        errorText="Required"
                        type="text"
                        isMultiline="true"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("attack", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        placeholder="Any behavioural issues?"
                        errorText="Required"
                        type="text"
                        isMultiline="true"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("behaviour", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        placeholder="What are you expectations"
                        errorText="Required"
                        type="text"
                        isMultiline="true"
                        validator={(value) => {
                          return multiValidator([
                            requiredValidator(value, "Reqiured"),
                          ]);
                        }}
                        onChange={(value, isValid) => {
                          regFormContext.setForm("expectations", {
                            value: value,
                            isValid: isValid,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <FormLabel id="photo-permission">
                        Permission for photos of you to be taken and posted on
                        Website/Social Media
                      </FormLabel>
                      <RadioGroup
                        onChange={(event) => {
                          regFormContext.setForm("dogPhotos", {
                            value: event.target.value,
                            isValid: true,
                          });
                        }}
                        aria-labelledby="photo-permission"
                        defaultValue="yes"
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <FormLabel id="dog-photo-permission">
                        Permission for photos of your dog to be taken and posted
                        on Website/Social Media
                      </FormLabel>
                      <RadioGroup
                        onChange={(event) => {
                          regFormContext.setForm("yourPhotos", {
                            value: event.target.value,
                            isValid: true,
                          });
                        }}
                        aria-labelledby="dog-photo-permission"
                        defaultValue="yes"
                        name="radio-buttons-group-2"
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <p>
                          Agreement to Hold Harmless Waiver & Assumption of
                          Risk:{" "}
                        </p>
                        <textarea
                          readOnly
                          className="form-control"
                          name="Agreement to Hold Harmless Waiver & Assumption of Risk"
                          rows="5"
                          placeholder="Agreement to Hold Harmless Waiver & Assumption of Risk:

I hereby acknowledge that I have voluntarily applied to participate in dog training classes with
Top Dog Training Academy, I am aware that there are inherent risks and hazards involved in
activities with and around dogs and I am participating in these classes or activities with
knowledge of these potential risks.
To participate in Top Dog Training Academy classes or other activities, I, being fully informed of
such risks, agree as follows:
(i)to assume all risks of such classes or activities;
(ii)to release Top Dog Training Academy, its instructors, and assistants from any and all claims
which I or my assigns may have that may result from such classes or activities, including those
relating to personal injury to myself, my dog(s), dogs in my charge, members of my family or
guests and those relating to damage to property, in each case caused directly or indirectly by
acts that might occur in or in relation to Top Dog Training Academy classes; and
(iii)to indemnify, defend, and hold Top Dog Training Academy, its instructors, and assistants,
harmless from all damage, loss, liability or expense, whether arising from negligence or any
other reason or cause whatsoever, including legal costs and lawyer’s fees, which result from
any damage caused directly or indirectly by myself or dog(s) which I own or handle."
                        ></textarea>
                        <div className="validate"></div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event) => {
                              regFormContext.setForm("terms", {
                                value:
                                  event.target.checked == true
                                    ? "signed"
                                    : "not-signed",
                                isValid: event.target.checked,
                              });
                            }}
                          />
                        }
                        label="I agree to the terms of service"
                      />
                    </div>
                  </div>

                  <div className="text-center">
                    <Button
                      onClick={formSubmitHandler}
                      title="Register"
                      isDisabled={
                        !(
                          regFormContext.isFormValid &&
                          regFormContext.isFormTouched
                        )
                      }
                      isLoading={isLoading}
                    />
                  </div>
                </FormControl>
              </div>
            </div>
          )}
          {!serviceData && !isServiceLoading && (
            <div style={{ textAlign: "center", color: "red" }}>
              Invalid Token
            </div>
          )}
          {isServiceLoading && <LoadingIndicator />}
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default RegistrationDetails;
