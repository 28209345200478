import { createContext } from "react";
const formatVacDate = () => {
  // Create a new Date object
  const today = new Date();

  // Get the year, month, and day from the date object
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month starts from 0, so adding 1 and padding with zero if needed
  const day = String(today.getDate()).padStart(2, "0"); // Pad day with zero if needed

  // Format the date as yyyy-mm-dd
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate; // Output: yyyy-mm-dd format of the current date
};
const initialRegFormValue = {
  name: { value: "", isValid: false },
  dogName: { value: "", isValid: false },
  email: { value: "", isValid: false },
  phone: { value: "", isValid: false },
  breed: { value: "", isValid: false },
  sex: { value: "", isValid: false },
  age: { value: "", isValid: false },
  spay: { value: "", isValid: false },
  vet: { value: "", isValid: false },
  vacDate: { value: formatVacDate(), isValid: true },
  medIssues: { value: "", isValid: false },
  healthIssues: { value: "", isValid: false },
  fears: { value: "", isValid: false },
  previousTraining: { value: "", isValid: false },
  skills: { value: "", isValid: false },
  attack: { value: "", isValid: false },
  behaviour: { value: "", isValid: false },
  expectations: { value: "", isValid: false },
  terms: { value: "signed", isValid: false },
  dogPhotoPermission: { value: "yes", isValid: false },
  yourPhotoPermission: { value: "yes", isValid: false },
  dogWeight: { value: "", isValid: false },
};

const RegFormContext = createContext({
  form: initialRegFormValue,
  isFormValid: false,
  isFormTouched: false,
  setForm: (itemId, newValue) => {},
  setWholeForm: (data) => {},
});

export default RegFormContext;
