import { useState, useCallback, useContext } from "react";
import SnackbarContext from "../contexts/SnackbarContext/snackbar-context";

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const snackbarContext = useContext(SnackbarContext);

  const sendRequest = useCallback(
    async (requestConfig, applyData) => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(requestConfig.url, {
          method: requestConfig.method ? requestConfig.method : "GET",
          headers: requestConfig.headers ? requestConfig.headers : {},
          body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
        });

        if (response === null) {
          throw new Error("Response is null!");
        }

        if (!response.ok) {
          throw new Error("Request failed!");
        }

        const text = await response.text();

        if (text && text.length) {
          const data = JSON.parse(text);
          setData(data);
          applyData(data);
        } else {
          setData(null);
          // throw new Error("Response body is empty!");
        }
      } catch (err) {
        snackbarContext.showSnackbar(
          "error",
          err.message || "Something went wrong!"
        );
        setError(err.message || "Something went wrong!");
      }
      setIsLoading(false);
    },
    [snackbarContext]
  );

  return [isLoading, error, sendRequest, data];
};

export default useHttp;
