import { Fragment, useMemo, useState, useEffect, useContext } from "react";
import CustomTable from "../../UI/CustomTable";
import Footer from "../../components/Home/Footer";
import AdminHeader from "../../components/Admin/AdminHeader";
import Button from "../../UI/Button/Button";
import StatusButton from "../../UI/StatusButton/StatusButton";
import RegFormContext from "../../contexts/RegFormContext/reg-form-context";
import { useNavigate } from "react-router-dom";
import DataContext from "../../contexts/DataContext/data-context";
import useHttp from "../../hooks/use-http";
import { formatDate } from "../../helpers/general";
import LoadingIndicator from "../../UI/LoadingIndicator/LoadingIndicator";
import SnackbarContext from "../../contexts/SnackbarContext/snackbar-context";

const Registrations = (props) => {
  const regFormContext = useContext(RegFormContext);
  const dataContext = useContext(DataContext);
  const snackbarContext = useContext(SnackbarContext);
  const [
    isRegistrationsDataLoading,
    regsitrationsDataError,
    getRegistrationsData,
    registrationsData,
  ] = useHttp();
  const navigate = useNavigate();
  const handleView = async (data) => {
    regFormContext.setWholeForm(data);
    navigate("/view-registration-details");
  };
  const viewStatusHandler = async (paymentIntent) => {
    try {
      window.location.href = `https://dashboard.stripe.com/test/payments/${paymentIntent}`;
    } catch (err) {
      console.error("Error in fetch request:", err);
    }
  };
  const getStatus = (params) => {
    const status = params.row.payment
      ? params.row.payment.status.toString().toUpperCase()
      : "LEGACY";
    let returnable;
    switch (status) {
      case "PAID":
        returnable = [status, "green"];
        break;
      case "AWAITING PAYMENT":
        returnable = [status, "lightOrange"];
        break;
      case "FAILED":
        returnable = [status, "lightRed"];
        break;
      case "REFUNDED":
        returnable = [status, "lightBlue"];
        break;
      default:
        returnable = [status, "lightGrey"];
    }
    return returnable;
  };

  const columns = useMemo(
    () => [
      { field: "name", headerName: "Name", width: 200 },
      { field: "email", headerName: "Email", width: 200 },
      { field: "phone", headerName: "Phone", width: 200 },
      { field: "program", headerName: "Program", width: 200 },
      { field: "registrationId", headerName: "Reg ID", width: 220 },
      {
        field: "updatedDate",
        headerName: "Date",
        width: 200,
        valueGetter: (params) => new Date(params.row.updateTime),
        renderCell: (params) => (
          <div style={{ textAlign: "center" }}>
            {new Date(params.row.updateTime).toDateString()}
          </div>
        ),
      },
      {
        field: "status",
        headerName: "Payment Status",
        width: 220,
        valueGetter: (params) => getStatus(params)[0],
        renderCell: (params) => (
          <div style={{ textAlign: "center" }}>
            <StatusButton
              color={getStatus(params)[1]}
              onClick={() => {
                if (!params.row.payment) {
                  return;
                }
                if (params.row.payment.payment_intent) {
                  viewStatusHandler(params.row.payment.payment_intent);
                }
              }}
              title={getStatus(params)[0]}
            ></StatusButton>
          </div>
        ),
      },
      {
        field: "view",
        headerName: "View",
        width: 120,
        renderCell: (params) => (
          <Button
            onClick={() => handleView(params.row)}
            title={"View"}
          ></Button>
        ),
      },
      {
        field: "delete",
        headerName: "Delete",
        width: 140,
        renderCell: (params) => (
          <Button
            color={"red"}
            onClick={() => deleteRegistrationHandler(params.row.registrationId)}
            title={"Delete"}
            isLoading={isDeletionLoading}
          />
        ),
      },
    ],
    []
  );
  useEffect(() => {
    if (dataContext.registrations.length < 1) {
      getRegistrationsData(
        {
          url: `${process.env.REACT_APP_BASE_API_URL}/website/registrations/getAll`,
        },
        (data) => dataContext.setRegistrations(data)
      );
    }
  }, []);
  const [isDeletionLoading, deleteError, deleteRegistration, deleteRegData] =
    useHttp();
  const deleteRegistrationHandler = (id) => {
    const userConfirmation = window.confirm(
      "Are you sure you want to delete this registration?"
    );
    if (userConfirmation) {
      deleteRegistration(
        {
          url: `${process.env.REACT_APP_BASE_API_URL}/website/registrations/delete?registrationId=${id}`,
          method: "POST",
        },
        (data) => {
          snackbarContext.showSnackbar("success", "Registration deleted!");
          getRegistrationsData(
            {
              url: `${process.env.REACT_APP_BASE_API_URL}/website/registrations/getAll`,
            },
            (data) => dataContext.setRegistrations(data)
          );
        }
      );
    }
  };

  return (
    <Fragment>
      <AdminHeader />
      <section id="contact" className="contact" style={{ paddingTop: "10%" }}>
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Registrations</h2>
          </div>

          <div className="row">
            <div
              className="col-lg-12 col-md-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <br />
              <br />
              {(isRegistrationsDataLoading || isDeletionLoading) && (
                <LoadingIndicator />
              )}
              <br />
              <br />
              {regsitrationsDataError ? (
                <div style={{ textAlign: "center", color: "red" }}>
                  {regsitrationsDataError}
                </div>
              ) : (
                <CustomTable
                  rows={dataContext.registrations.map((row) => {
                    return { ...row, id: row.registrationId };
                  })}
                  columns={columns}
                  hasSendEmail={true}
                />
              )}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Fragment>
  );
};

export default Registrations;
