const Footer = (props) => {
  return (
    <footer id="footer">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-lg-12 text-lg-left text-center">
            <div className="copyright">
              &copy; Copyright <strong>Top Dog Training Academy</strong>. All
              Rights Reserved
            </div>
            <div className="credits">
              Designed by <a href="https://binariusconsulting.ca/">Binarius</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
