import { createContext } from "react";
const DataContext = createContext({
  registrations: [],
  setRegistrations: (registrations) => {},
  services: [],
  setServices: (services) => {},
  contactRequests: [],
  setContactRequests: (contextRequests) => {},
  team: [],
  setTeam: (team) => {},
});

export default DataContext;
