import { useContext, useEffect, useState } from "react";
import Button from "../../../UI/Button/Button";
import Input from "../../../UI/Input/Input";
import { mapObject, reverseMapObject } from "../../../helpers/general";
import { requiredValidator } from "../../../helpers/form-validators";
import useHttp from "../../../hooks/use-http";
import SnackbarContext from "../../../contexts/SnackbarContext/snackbar-context";
import LoadingIndicator from "../../../UI/LoadingIndicator/LoadingIndicator";
import DataContext from "../../../contexts/DataContext/data-context";

const ServiceDetailForm = (props) => {
  const snackbarContext = useContext(SnackbarContext);
  const dataContext = useContext(DataContext);
  const [isFormTouched, setFormTouch] = useState(false);
  const [isFormValid, setFormValidity] = useState(false);
  const [
    isUpdateServiceLoading,
    updateServiceError,
    updateService,
    updateServiceData,
  ] = useHttp();
  const [
    isDisableServiceLoading,
    disableServiceError,
    disableService,
    disableServiceData,
  ] = useHttp();

  const [isAddServiceLoading, addServiceError, addService, addServiceData] =
    useHttp();

  const [
    isServicesDataLoading,
    servicesDataError,
    getServicesData,
    servicesData,
  ] = useHttp();
  const initialData = reverseMapObject(props.service);
  const [service, setService] = useState(initialData);
  useEffect(() => {
    setService(reverseMapObject(props.service));
  }, [props.service]);
  const checkFormValidity = () => {
    if ({ ...service } == {}) {
      setFormValidity(false);

      return;
    }
    if (
      service.service_name == null ||
      service.service_desc == null ||
      service.service_price == null ||
      service.price == null
    ) {
      setFormValidity(false);

      return;
    }
    setFormValidity(
      service.service_name.isValid && service.service_desc.isValid
    );
    return;
  };

  const updateServiceHandler = () => {
    const body = mapObject(service);
    updateService(
      {
        url: `${process.env.REACT_APP_BASE_API_URL}/website/services/update?serviceId=${service.serviceId.value}`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      },
      (data) => {
        getServicesData(
          {
            url: `${process.env.REACT_APP_BASE_API_URL}/website/services/getAll?pageNumber=1&showDisabled=true`,
          },
          (data) => {
            dataContext.setServices(data);
            snackbarContext.showSnackbar("success", "Service updated!");
          }
        );
      }
    );
  };
  const disableServiceHandler = (isDisabled) => {
    updateService(
      {
        url: `${process.env.REACT_APP_BASE_API_URL}/website/services/update?serviceId=${service.serviceId.value}`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: { isDisabled: isDisabled },
      },
      (data) => {
        getServicesData(
          {
            url: `${process.env.REACT_APP_BASE_API_URL}/website/services/getAll?pageNumber=1&showDisabled=true`,
          },
          (data) => {
            dataContext.setServices(data);
            snackbarContext.showSnackbar("success", "Service updated!");
          }
        );
        service.isDisabled = { value: isDisabled, isValid: true };
        setService(service);
      }
    );
  };

  const addServiceHandler = (id) => {
    const body = mapObject(service);
    body.service_priority = dataContext.services.length + 1;
    addService(
      {
        url: `${process.env.REACT_APP_BASE_API_URL}/website/services/create`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      },
      (data) => {
        getServicesData(
          {
            url: `${process.env.REACT_APP_BASE_API_URL}/website/services/getAll?pageNumber=1&showDisabled=true`,
          },
          (data) => {
            dataContext.setServices(data);
            snackbarContext.showSnackbar("success", "Service added!");
          }
        );
      }
    );
  };
  return (
    <div className="row">
      <div
        className="col-lg-6 col-md-12"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <div>
          {(isAddServiceLoading ||
            isDisableServiceLoading ||
            isUpdateServiceLoading) && <LoadingIndicator />}
        </div>
        <Input
          label={"Service Name"}
          initialValue={
            service.service_name != null ? service.service_name.value : ""
          }
          validator={(value) => requiredValidator(value, "Required")}
          onChange={(value, isValid) => {
            setService((prevValue) => {
              return { ...prevValue, service_name: { value, isValid } };
            });
            checkFormValidity();
          }}
        />

        <Input
          label={"Service Time"}
          initialValue={
            service.service_time != null ? service.service_time.value : ""
          }
          validator={(value) => requiredValidator(value, "Required")}
          onChange={(value, isValid) => {
            setService((prevValue) => {
              return { ...prevValue, service_time: { value, isValid } };
            });
            checkFormValidity();
          }}
        />

        <Input
          label={"Service Price"}
          initialValue={
            service.service_price != null ? service.service_price.value : ""
          }
          validator={(value) => requiredValidator(value, "Required")}
          onChange={(value, isValid) => {
            setService((prevValue) => {
              return { ...prevValue, service_price: { value, isValid } };
            });
            checkFormValidity();
          }}
        />

        <Input
          label={"Final Price (Charged to clients)"}
          initialValue={service.price != null ? service.price.value : 0}
          type={"number"}
          validator={(value) => requiredValidator(value, "Required")}
          onChange={(value, isValid) => {
            setService((prevValue) => {
              return { ...prevValue, price: { value, isValid } };
            });
            checkFormValidity();
          }}
        />

        <Input
          label={"Service Desc"}
          initialValue={
            service.service_desc != null ? service.service_desc.value : ""
          }
          validator={(value) => requiredValidator(value, "Required")}
          onChange={(value, isValid) => {
            setService((prevValue) => {
              return { ...prevValue, service_desc: { value, isValid } };
            });
            checkFormValidity();
          }}
          isMultiline={true}
        />
      </div>
      <div
        className="col-lg-6 col-md-12"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <Button
          title="Add"
          isDisabled={!isFormValid || service.serviceId != null}
          onClick={addServiceHandler}
        />

        <Button
          title="Update"
          isDisabled={service.serviceId == null || !isFormValid}
          onClick={updateServiceHandler}
        />

        <Button
          title={
            service.isDisabled
              ? service.isDisabled.value === true
                ? "Enable"
                : "Disable"
              : "Disable"
          }
          isDisabled={service.serviceId == null}
          onClick={() =>
            disableServiceHandler(
              service.isDisabled
                ? service.isDisabled.value === true
                  ? false
                  : true
                : true
            )
          }
        />
      </div>
    </div>
  );
};

export default ServiceDetailForm;
