import { Fragment, useMemo, useState, useEffect, useContext } from "react";
import CustomTable from "../../../UI/CustomTable";
import Footer from "../../../components/Home/Footer";
import AdminHeader from "../../../components/Admin/AdminHeader";
import DataContext from "../../../contexts/DataContext/data-context";
import useHttp from "../../../hooks/use-http";
import { useNavigate } from "react-router-dom";
import Button from "../../../UI/Button/Button";

const Team = (props) => {
  const dataContext = useContext(DataContext);
  const [isTeamDataLoading, regsitrationsDataError, getTeamData, teamData] =
    useHttp();
  const navigate = useNavigate();
  const handleEdit = async (data) => {
    navigate(`/view-team-details?teamMemberId=${data.teamMemberId}`);
  };

  const columns = useMemo(
    () => [
      { field: "name", headerName: "Member Name", width: 200 },
      { field: "title", headerName: "Member Title", width: 200 },
      {
        field: "eidt",
        headerName: "Edit",
        width: 150,
        renderCell: (params) => (
          <Button
            onClick={() => handleEdit(params.row)}
            title={"Edit"}
          ></Button>
        ),
      },
    ],
    []
  );
  useEffect(() => {
    if (dataContext.team.length < 1) {
      getTeamData(
        { url: `${process.env.REACT_APP_BASE_API_URL}/website/team/getAll` },
        (data) => dataContext.setTeam(data)
      );
    }
  }, [dataContext.team]);

  return (
    <Fragment>
      <AdminHeader />
      <section id="contact" className="contact" style={{ paddingTop: "10%" }}>
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Team</h2>
          </div>

          <div className="row">
            <div
              className="col-lg-12 col-md-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <br />
              <br />
              <br />
              <br />
              {regsitrationsDataError ? (
                <div style={{ textAlign: "center", color: "red" }}>
                  {regsitrationsDataError}
                </div>
              ) : (
                <CustomTable
                  rows={dataContext.team.map((row) => {
                    return { ...row, id: row.teamMemberId };
                  })}
                  columns={columns}
                />
              )}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Fragment>
  );
};

export default Team;
