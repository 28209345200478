import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Fragment, useState } from "react";
import "./CustomTable.css";
import Button from "./Button/Button";

const CustomTable = (props) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelection = (selection) => {
    const selectedRows = selection.map((selectedId) =>
      props.rows.find((row) => row.id === selectedId)
    );
    setSelectedRows(selectedRows);
  };
  const handleSendEmail = () => {
    const emailAddresses = selectedRows.map((row) => row.email).join(";");
    const subject = encodeURIComponent("Subject for your email");
    const body = encodeURIComponent("Compose your email body here.");

    window.location.href = `mailto:${emailAddresses}?subject=${subject}&body=${body}`;
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarFilterButton />
        <GridToolbarExport />

        {props.hasSendEmail && (
          <Button
            title="Send Email"
            onClick={handleSendEmail}
            isDisabled={selectedRows.length == 0}
          ></Button>
        )}
      </GridToolbarContainer>
    );
  }
  return (
    <Fragment>
      <div>
        <DataGrid
          className="datagrid-container"
          sx={{
            boxShadow: 2,
          }}
          rows={props.rows}
          columns={props.columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 50]}
          disableRowSelectionOnClick
          checkboxSelection
          slots={{
            toolbar: CustomToolbar,
          }}
          onRowSelectionModelChange={handleRowSelection}
        />
      </div>
    </Fragment>
  );
};
export default CustomTable;
