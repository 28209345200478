import { Fragment } from "react";
import Header from "../../components/Home/Header";
import Footer from "../../components/Home/Footer";
import successImg from "../../assets/img/payment/success.png";
import failedImg from "../../assets/img/payment/failed.png";
import Button from "../../UI/Button/Button";
import { useNavigate } from "react-router-dom";

const PostPayment = (props) => {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const success = queryParameters.get("success");
  return (
    <Fragment>
      <Header />
      <section id="contact" className="contact" style={{ paddingTop: "10%" }}>
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Payment {success === "true" ? "Successful" : "Failed"}</h2>
          </div>

          <div style={{ maxWidth: "45%", margin: "auto", textAlign: "center" }}>
            {success === "true"
              ? "Thank you for your payment! You can expect to receive a confirmation email containing the registration details shortly. Should you have any questions or require further information, please don't hesitate to reach out to us at jdnic07@gmail.com"
              : "Payment failed! Please try again or reach out to us at jdnic07@gmail.com"}
          </div>

          <div
            style={{ textAlign: "center" }}
            className="col-lg-12 col-md-12"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div>
              <img
                src={success === "true" ? successImg : failedImg}
                alt=""
                style={{ height: 400 }}
              />
            </div>
            <Button
              title={"Home"}
              onClick={(event) => {
                event.preventDefault();
                navigate("/");
              }}
            />
          </div>
        </div>
      </section>

      <Footer />
    </Fragment>
  );
};

export default PostPayment;
