import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import SnackbarContext from "./snackbar-context";

const SnackbarProvider = (props) => {
  const [snackbarState, setSnackbarState] = useState({
    isOpen: false,
    type: "success",
    message: "This is a Success message!",
  });
  return (
    <SnackbarContext.Provider
      value={{
        isOpen: snackbarState.isOpen,
        type: snackbarState.type,
        showSnackbar: (type, message) => {
          setSnackbarState({ type: type, message: message, isOpen: true });
        },
      }}
    >
      <Snackbar
        open={snackbarState.isOpen}
        autoHideDuration={6000}
        onClose={() =>
          setSnackbarState((prevState) => {
            return { ...prevState, isOpen: false };
          })
        }
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={snackbarState.type} sx={{ width: "100%" }}>
          {snackbarState.message}
        </Alert>
      </Snackbar>
      {/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}
      {props.children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
