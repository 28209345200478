import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import classes from "./Button.module.css";
const Button = ({ title, onClick, isDisabled, color, isLoading }) => {
  return !isDisabled ? (
    !isLoading ? (
      <button
        className={classes.button}
        onClick={onClick}
        style={{ backgroundColor: color }}
      >
        {title}
      </button>
    ) : (
      <LoadingIndicator />
    )
  ) : (
    <button className={classes["button-disabled"]}>{title}</button>
  );
};

export default Button;
