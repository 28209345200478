import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const AdminItem = (props) => {
  const navigate = useNavigate();
  return (
    <div
      className="col-lg-3 col-md-4"
      style={{ padding: "5px 10px", cursor: "pointer" }}
      onClick={() => navigate(props.item.link)}
    >
      <div className="icon-box">
        {props.item.icon}
        <Box sx={{ width: 20 }} />
        <h3 style={{ color: "black" }}>{props.item.title}</h3>
      </div>
    </div>
  );
};

export default AdminItem;
