import Login from "../../pages/Admin/Login";

const AuthWrapper = (props) => {
  const isLoggedIn = true;
  if (isLoggedIn) {
    return props.children;
  } else {
    return <Login />;
  }
};

export default AuthWrapper;
