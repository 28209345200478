import { Fragment, useContext, useEffect, useState } from "react";
import Footer from "../../components/Home/Footer";

import { Box } from "@mui/material";
import AdminHeader from "../../components/Admin/AdminHeader";
import DataContext from "../../contexts/DataContext/data-context";

const GenerateToken = (props) => {
  const dataContext = useContext(DataContext);
  const clickHandler = (event) => {
    setVisibility(true);
  };

  const programChangeHandler = (event) => {
    setToken(event.target.value);
    setVisibility(false);
  };
  const [token, setToken] = useState("");
  const [isVisible, setVisibility] = useState(false);
  return (
    <Fragment>
      <AdminHeader />
      <section id="contact" className="contact" style={{ paddingTop: "10%" }}>
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Generate Token</h2>
          </div>

          <div
            className="col-lg-12 col-md-12"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <br />
            <br />
            <br />
            <div>
              <label htmlFor="program">Choose a program:</label>
              <select
                name="Program"
                id="program"
                data-rule="minlen:1"
                data-msg="Please select one"
                onChange={programChangeHandler}
                value={token}
              >
                <option> Please Select A Program </option>
                {dataContext.services.map((service) => (
                  <option value={service.serviceId} key={service.serviceId}>
                    {service.service_name}
                  </option>
                ))}
              </select>
              <Box sx={{ height: "20px" }}></Box>
              {isVisible ? (
                <p id="usertoken">
                  Please provide this token to the user to register: {token}
                </p>
              ) : null}
            </div>
            <Box sx={{ height: "20px" }}></Box>
            <div>
              <button
                style={{
                  backgroundColor: "#57aae1",
                  borderRadius: "20px",
                  border: "none",
                  color: "white",
                  padding: "10px 30px 10px 30px",
                }}
                className="get-started"
                id="generateToken"
                onClick={clickHandler}
              >
                Generate Token
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default GenerateToken;
