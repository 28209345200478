import { Fragment, useMemo, useState, useEffect, useContext } from "react";
import CustomTable from "../../UI/CustomTable";

import Footer from "../../components/Home/Footer";
import AdminHeader from "../../components/Admin/AdminHeader";
import axios from "axios";
import DataContext from "../../contexts/DataContext/data-context";
import useHttp from "../../hooks/use-http";
import Button from "../../UI/Button/Button";
import SnackbarContext from "../../contexts/SnackbarContext/snackbar-context";
import LoadingIndicator from "../../UI/LoadingIndicator/LoadingIndicator";

const ContactRequests = (props) => {
  const dataContext = useContext(DataContext);
  const snackbarContext = useContext(SnackbarContext);
  const [isContactDataLoading, contactDataError, getContactData, contactData] =
    useHttp();
  useEffect(() => {
    if (dataContext.contactRequests.length < 1) {
      getContactData(
        {
          url: `${process.env.REACT_APP_BASE_API_URL}/website/contacts/getAll?pageNumber=1`,
        },
        (data) => dataContext.setContactRequests(data)
      );
    }
  }, []);
  const [
    isDeleteContactLoading,
    contactDeleteError,
    deleteContact,
    deleteContactData,
  ] = useHttp();

  const deleteContactHandler = (id) => {
    const userConfirmation = window.confirm(
      "Are you sure you want to delete this contact?"
    );
    if (userConfirmation) {
      deleteContact(
        {
          url: `${process.env.REACT_APP_BASE_API_URL}/website/contacts/delete?contactId=${id}`,
          method: "POST",
        },
        (data) => {
          snackbarContext.showSnackbar("success", "Contact deleted!");
          getContactData(
            {
              url: `${process.env.REACT_APP_BASE_API_URL}/website/contacts/getAll?pageNumber=1`,
            },
            (data) => dataContext.setContactRequests(data)
          );
        }
      );
    }
  };
  const columns = useMemo(
    () => [
      { field: "contactName", headerName: "Name", width: 200 },
      { field: "contactEmail", headerName: "Email", width: 200 },
      { field: "contactSubject", headerName: "Subject", width: 200 },
      { field: "contactMessage", headerName: "Message", width: 500 },
      {
        field: "updatedTime",
        headerName: "Date",
        width: 200,
        renderCell: (params) => (
          <div style={{ textAlign: "center" }}>
            {new Date(params.row.updateTime).toDateString()}
          </div>
        ),
      },
      {
        field: "",
        headerName: "Delete",
        width: 150,
        renderCell: (params) => (
          <div style={{ textAlign: "center" }}>
            <Button
              title={"Delete"}
              onClick={() => {
                deleteContactHandler(params.row.contactId);
              }}
            />
          </div>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <AdminHeader />
      <section id="contact" className="contact" style={{ paddingTop: "10%" }}>
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Contacts</h2>
          </div>

          <div className="row">
            <div
              className="col-lg-12 col-md-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <br />
              <br />
              {(isContactDataLoading || isDeleteContactLoading) && (
                <LoadingIndicator />
              )}
              <br />
              <br />
              <CustomTable
                rows={dataContext.contactRequests.map((row) => {
                  return { ...row, id: Math.random() };
                })}
                columns={columns}
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Fragment>
  );
};

export default ContactRequests;
