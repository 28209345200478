import classes from "./ViewRegistrationItem.module.css";

const ViewRegstrationItem = ({ title, value }) => {
  return (
    <div className="form-group">
      <label className={classes.label}>{title}</label>
      <p id="breed">{value}</p>
    </div>
  );
};
export default ViewRegstrationItem;
