import { useState } from "react";
import DataContext from "./data-context";

const DataProvider = (props) => {
  const [registrations, setRegistrations] = useState([]);
  const [services, setServices] = useState([]);
  const [contactRequests, setContactRequests] = useState([]);
  const [team, setTeam] = useState([]);

  const setRegistrationsHandler = (registrations) => {
    setRegistrations(registrations);
  };
  const setServicesHandler = (services) => {
    setServices(services);
  };
  const setContactRequestsHandler = (contactRequests) => {
    setContactRequests(contactRequests);
  };
  const setTeamHandler = (team) => {
    setTeam(team);
  };

  return (
    <DataContext.Provider
      value={{
        registrations: registrations,
        setRegistrations: setRegistrationsHandler,
        services: services,
        setServices: setServicesHandler,
        contactRequests: contactRequests,
        setContactRequests: setContactRequestsHandler,
        team: team,
        setTeam: setTeamHandler,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export default DataProvider;
