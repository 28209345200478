const mapObject = (inputObj) => {
  const mappedObj = {};
  for (const key in inputObj) {
    if (Object.hasOwnProperty.call(inputObj, key)) {
      const element = inputObj[key];
      mappedObj[key] = element.value;
    }
  }
  return mappedObj;
};

const reverseMapObject = (inputObj) => {
  const reversedObj = {};
  for (const key in inputObj) {
    if (Object.hasOwnProperty.call(inputObj, key)) {
      const value = inputObj[key];
      reversedObj[key] = { value: value, isValid: true };
    }
  }
  return reversedObj;
};

const formatDate = (date) => {
  try {
    // Extracting the date components
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because month is 0-indexed
    let day = String(date.getDate()).padStart(2, "0");

    // Concatenating the components in 'YYYY-MM-DD' format
    let formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  } catch (err) {
    return `Error: ${err.toString()}`;
  }
};

module.exports = { mapObject, reverseMapObject, formatDate };
