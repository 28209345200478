import { Fragment, useContext } from "react";
import AdminHeader from "../../../components/Admin/AdminHeader";
import Footer from "../../../components/Home/Footer";
import Button from "../../../UI/Button/Button";
import RegFormContext from "../../../contexts/RegFormContext/reg-form-context";
import ViewRegstrationItem from "./ViewRegistrationItem";
import { NavLink } from "react-router-dom";

const ViewRegistrationDetails = (props) => {
  const regFormContext = useContext(RegFormContext);
  return (
    <Fragment>
      <AdminHeader />
      <section id="contact" className="contact">
        <div className="container">
          <br />
          <br />
          <br />
          <div className="section-title" data-aos="fade-up">
            <h2>Form Details</h2>
          </div>

          <div className="row">
            <div className="col-md-12" data-aos="fade-up" data-aos-delay="300">
              <div className="row">
                <div className="col-md-10"></div>
                <div className="col-md-2">
                  <Button title={"Print"} onClick={() => window.print()} />
                </div>
              </div>
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <ViewRegstrationItem
                      title={"Program Chosen:"}
                      value={regFormContext.form.program.value}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <ViewRegstrationItem
                      title={"Name:"}
                      value={regFormContext.form.name.value}
                    />
                  </div>
                  <div className="col-md-6">
                    <ViewRegstrationItem
                      title={"Dog's Name:"}
                      value={regFormContext.form.dogName.value}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <ViewRegstrationItem
                      title={"Phone:"}
                      value={regFormContext.form.phone.value}
                    />
                  </div>
                  <div className="col-md-6">
                    <ViewRegstrationItem
                      title={"Email:"}
                      value={regFormContext.form.email.value}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <ViewRegstrationItem
                      title={"Breed:"}
                      value={regFormContext.form.breed.value}
                    />
                  </div>
                  <div className="col-md-2">
                    <ViewRegstrationItem
                      title={"Dog's Sex:"}
                      value={regFormContext.form.sex.value}
                    />
                  </div>
                  <div className="col-md-2">
                    <ViewRegstrationItem
                      title={"Dog's Age:"}
                      value={regFormContext.form.age.value}
                    />
                  </div>
                  <div className="col-md-2">
                    <ViewRegstrationItem
                      title={"Spay/Neutered:"}
                      value={regFormContext.form.spay.value}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <ViewRegstrationItem
                      title={"Vet Clinic:"}
                      value={regFormContext.form.vet.value}
                    />
                  </div>
                  <div className="col-md-4">
                    <ViewRegstrationItem
                      title={"Date of last Vaccinations/or titer test:"}
                      value={regFormContext.form.vacDate.value}
                    />
                  </div>
                  <div className="col-md-2">
                    <ViewRegstrationItem
                      title={"Dog's Weight"}
                      value={regFormContext.form.dogWeight.value}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <ViewRegstrationItem
                      title={"Any medical or mobility issues:"}
                      value={regFormContext.form.medIssues.value}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <ViewRegstrationItem
                      title={"Previous health issues or injury:"}
                      value={regFormContext.form.healthIssues.value}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <ViewRegstrationItem
                      title={"Any fears:"}
                      value={regFormContext.form.fears.value}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <ViewRegstrationItem
                      title={"Any previous training (if so with who):"}
                      value={regFormContext.form.previousTraining.value}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <ViewRegstrationItem
                      title={"What skills does your dog have now:"}
                      value={regFormContext.form.skills.value}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <ViewRegstrationItem
                      title={
                        "Has your dog been in a dog fight or been attacked by another dog? Has your dog bit a human?:"
                      }
                      value={regFormContext.form.attack.value}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <ViewRegstrationItem
                      title={"Any behavioural issues?:"}
                      value={regFormContext.form.behaviour.value}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <ViewRegstrationItem
                      title={" What are you expectations:"}
                      value={regFormContext.form.expectations.value}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <ViewRegstrationItem
                      title={
                        "Permission for photos of you to be taken and posted on Website/FB:"
                      }
                      value={
                        regFormContext.form.yourPhotoPermission.value
                          .toString()
                          .toLowerCase() === "yes"
                          ? "Yes"
                          : "No"
                      }
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <ViewRegstrationItem
                      title={
                        "Permission for photos of your dog to be taken and posted on Website/FB:"
                      }
                      value={
                        regFormContext.form.dogPhotoPermission.value
                          .toString()
                          .toLowerCase() === "yes"
                          ? "Yes"
                          : "No"
                      }
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label style={{ fontWeight: 700 }}>
                        Agreement to Hold Harmless Waiver & Assumption of Risk:
                      </label>
                      <p>
                        I hereby acknowledge that I have voluntarily applied to
                        participate in dog training classes with Top Dog
                        Training Academy, I am aware that there are inherent
                        risks and hazards involved in activities with and around
                        dogs and I am participating in these classes or
                        activities with knowledge of these potential risks. To
                        participate in Top Dog Training Academy classes or other
                        activities, I, being fully informed of such risks, agree
                        as follows:
                        <br />
                        <br />
                        (i)to assume all risks of such classes or activities;
                        <br />
                        (ii)to release Top Dog Training Academy, its
                        instructors, and assistants from any and all claims
                        which I or my assigns may have that may result from such
                        classes or activities, including those relating to
                        personal injury to myself, my dog(s), dogs in my charge,
                        members of my family or guests and those relating to
                        damage to property, in each case caused directly or
                        indirectly by acts that might occur in or in relation to
                        Top Dog Training Academy classes; and
                        <br />
                        (iii)to indemnify, defend, and hold Top Dog Training
                        Academy, its instructors, and assistants, harmless from
                        all damage, loss, liability or expense, whether arising
                        from negligence or any other reason or cause whatsoever,
                        including legal costs and lawyer’s fees, which result
                        from any damage caused directly or indirectly by myself
                        or dog(s) which I own or handle.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <ViewRegstrationItem
                      title={"Terms Accepted:"}
                      value={
                        regFormContext.form.terms.value
                          .toString()
                          .toLowerCase() === "signed"
                          ? "Yes"
                          : "No"
                      }
                    />
                  </div>
                </div>

                <div className="text-center">
                  <NavLink to="/registrations">
                    <Button title={"Previous Page"} />
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default ViewRegistrationDetails;
