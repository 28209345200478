import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Carousel } from "@trendyol-js/react-carousel";
import img1 from "../../assets/img/testimonials/testimonials-1.jpg";
import img2 from "../../assets/img/testimonials/testimonials-2.jpg";
import img3 from "../../assets/img/testimonials/testimonials-3.jpg";
import img4 from "../../assets/img/testimonials/testimonials-4.jpg";
import img5 from "../../assets/img/testimonials/testimonials-5.jpg";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
const testimonials = [
  {
    img: img1,
    name: "Nichole Speiss",
    dogName: "& dog name",
    testimonial:
      "I highly recommend Top Dog Training Academy for training you and your dog to develop skills for improving as owners of balanced canine citizens, with a relationship to be proud of. As an owner of a strong breed I am extremely grateful to have Jackie’s help in their upbringing and essential skills development, and so much more, which ultimately earns the privilege of freedom they so deserve. If you are striving for the best for your dog, Jackie’s commitment is unsurpassed!",
  },
  {
    img: img2,
    name: "Natalie Wing",
    dogName: "& Rook",
    testimonial:
      "Thanks to Jackie, we have an exceptionally well-balanced dog (who otherwise might have been a whole lot of trouble) and an awesome relationship with our dog. We have done a mix of group sessions, private sessions, and participated in Jackie's amazing train + drain program, and highly recommend all of her services. A very worthwhile investment.",
  },
  {
    img: img3,
    name: "Aven-Lee Enzenauer",
    dogName: "& Moose",
    testimonial:
      "She really catered to our large breed dog and the needs we had. Even throughout his growth spurts and adolescent rough patches, she guided us through it. She's made it so much easier for us as dog owners and given us all the tools we need to have a well-behaved dog. We can't say enough good things about her and her teachings. Thanks Jackie!",
  },
  {
    img: img4,
    name: "Binnitta Ritchie",
    dogName: "& Salsa",
    testimonial: "Consistent, progressive and positive training!",
  },
  {
    img: img5,
    name: "Reba Miller",
    dogName: "& Yam",
    testimonial:
      "I am pleased with the philosophy Jackie practises from. She is also willing to use certain tools for certain purposes, not a one-size-fits-all approach. I appreciate her organizational skills, quality products, edibles as well as toys and teaching tools, and affordable pricing. I have fun with my dog and the time goes very quickly.",
  },
];

const Testimonials = (props) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const number = isDesktop ? 2 : 1;
  return (
    <section id="testimonials" className="testimonials section-bg">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Testimonials</h2>
        </div>

        <Carousel
          autoSwipe={1}
          show={number}
          responsive={true}
          swipeOn={0.2}
          swiping={true}
          slide={number}
          hideArrows={true}
        >
          <div className="testimonial-wrap">
            <div className="testimonial-item">
              <img src={img1} className="testimonial-img" alt="" />
              <h3>Nichole Speiss</h3>
              <h4>& dog name</h4>
              <p>
                <i className="bx bxs-quote-alt-left quote-icon-left"></i>I
                highly recommend Top Dog Training Academy for training you and
                your dog to develop skills for improving as owners of balanced
                canine citizens, with a relationship to be proud of. As an owner
                of a strong breed I am extremely grateful to have Jackie’s help
                in their upbringing and essential skills development, and so
                much more, which ultimately earns the privilege of freedom they
                so deserve.If you are striving for the best for your dog,
                Jackie’s commitment is unsurpassed!
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div>

          <div className="testimonial-wrap">
            <div className="testimonial-item">
              <img src={img2} className="testimonial-img" alt="" />
              <h3>Natalie Wing</h3>
              <h4>& Rook</h4>
              <p>
                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                Thanks to Jackie, we have an exceptionally well-balanced dog
                (who otherwise might have been a whole lot of trouble) and an
                awesome relationship with our dog. We have done a mix of group
                sessions, private sessions, and participated in Jackie's amazing
                train + drain program, and highly recommend all of her services.
                A very worthwhile investment.
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div>

          <div className="testimonial-wrap">
            <div className="testimonial-item">
              <img src={img3} className="testimonial-img" alt="" />
              <h3>Aven-Lee Enzenauer</h3>
              <h4>& Moose</h4>
              <p>
                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                She really catered to our large breed dog and the needs we had.
                Even throughout his growth spurts and adolescent rough patches,
                she guided us through it. She's made it so much easier for us as
                dog owners and given us all the tools we need to have a well
                behaved dog. We can't say enough good things about her and her
                teachings. Thanks Jackie!
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div>

          <div className="testimonial-wrap">
            <div className="testimonial-item">
              <img src={img4} className="testimonial-img" alt="" />
              <h3>Binnitta Ritchie</h3>
              <h4>& Salsa</h4>
              <p>
                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                Consistent, progressive and positive training!
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div>

          <div className="testimonial-wrap">
            <div className="testimonial-item">
              <img src={img5} className="testimonial-img" alt="" />
              <h3>Reba Miller</h3>
              <h4>& Yam</h4>
              <p>
                <i className="bx bxs-quote-alt-left quote-icon-left"></i>I am
                pleased with the philosophy Jackie practises from. She is also
                willing to use certain tools for certain purposes, not a
                one-size-fits-all approach. I appreciate her organizational
                skills, quality products, edibles as well as toys and teaching
                tools, and affordable pricing. I have fun with my dog and the
                time goes very quickly.
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div>
        </Carousel>
      </div>
    </section>
  );
};

export default Testimonials;
