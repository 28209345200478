import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom"; // Replace with your actual AuthContext path
import { AuthContext } from "../contexts/AuthContext/auth-context";

const PrivateRoute = () => {
  const { currentUser } = useContext(AuthContext);

  return currentUser ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
