import { useState } from "react";
import RegFormContext from "./reg-form-context";
import { reverseMapObject } from "../../helpers/general";
const formatVacDate = () => {
  // Create a new Date object
  const today = new Date();

  // Get the year, month, and day from the date object
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month starts from 0, so adding 1 and padding with zero if needed
  const day = String(today.getDate()).padStart(2, "0"); // Pad day with zero if needed

  // Format the date as yyyy-mm-dd
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate; // Output: yyyy-mm-dd format of the current date
};
const initialRegFormValue = {
  name: { value: "", isValid: false },
  dogName: { value: "", isValid: false },
  email: { value: "", isValid: false },
  phone: { value: "", isValid: false },
  breed: { value: "", isValid: false },
  sex: { value: "", isValid: false },
  age: { value: "", isValid: false },
  spay: { value: "", isValid: false },
  vet: { value: "", isValid: false },
  vacDate: { value: formatVacDate(), isValid: true },
  medIssues: { value: "", isValid: false },
  healthIssues: { value: "", isValid: false },
  fears: { value: "", isValid: false },
  previousTraining: { value: "", isValid: false },
  skills: { value: "", isValid: false },
  attack: { value: "", isValid: false },
  behaviour: { value: "", isValid: false },
  expectations: { value: "", isValid: false },
  terms: { value: "", isValid: false },
  dogPhotoPermission: { value: "yes", isValid: true },
  yourPhotoPermission: { value: "yes", isValid: true },
  dogWeight: { value: "", isValid: false },
};

const RegFormProvider = (props) => {
  const [regFormState, setRegForm] = useState(initialRegFormValue);
  const [isFormValid, setFormValidity] = useState(false);
  const [isFormTouched, setFormTouched] = useState(false);

  const checkFormValidity = (dataObject) => {
    for (const key in dataObject) {
      if (Object.hasOwnProperty.call(dataObject, key)) {
        if (!dataObject[key].isValid) {
          setFormValidity(false);
          return false;
        }
      }
    }
    setFormValidity(true);
    return true;
  };

  const setFormHandler = (itemId, newItemValue) => {
    setFormTouched(true);
    setRegForm((prevValue) => {
      const newFormValue = { ...prevValue };
      newFormValue[itemId] = newItemValue;
      checkFormValidity(newFormValue);
      return newFormValue;
    });
  };

  const setWholeFormHandler = (regFormData) => {
    const data = reverseMapObject(regFormData);
    setRegForm(data);
  };

  return (
    <RegFormContext.Provider
      value={{
        form: regFormState,
        isFormValid: isFormValid,
        isFormTouched: isFormTouched,
        setForm: setFormHandler,
        setWholeForm: setWholeFormHandler,
      }}
    >
      {props.children}
    </RegFormContext.Provider>
  );
};

export default RegFormProvider;
