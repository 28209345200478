import { Box, CircularProgress } from "@mui/material";

const LoadingIndicator = (props) => {
  return (
    <Box sx={{ display: "flex", alignContent: "center", alignItems: "center" }}>
      <CircularProgress />
    </Box>
  );
};

export default LoadingIndicator;
