import { NavLink, useNavigate } from "react-router-dom";
import logo from "/Volumes/T7/Documents/VS Code Projects/topdogtraining/src/assets/img/topdoglogo.png";

const Header = (props) => {
  const navigate = useNavigate();
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <header
      id="header"
      className="fixed-top d-flex align-items-center"
      style={{
        background: "#fff",
        boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div className="container d-flex align-items-center">
        <div className="col-lg-6 logo mr-auto">
          <NavLink
            onClick={() => {
              scrollToTop();
            }}
          >
            <span>
              <img src={logo} className="img-fluid animated" alt="" />
            </span>
          </NavLink>
        </div>

        <nav className="col-lg-6 nav-menu d-none d-lg-block">
          <ul>
            <li
              onClick={() => {
                navigate("/");
                scrollToTop();
              }}
            >
              <NavLink>Home</NavLink>
            </li>
            <li
              onClick={() => {
                navigate("/");
                scrollToSection("about");
              }}
            >
              <NavLink>About</NavLink>
            </li>
            <li
              onClick={() => {
                navigate("/");
                scrollToSection("services");
              }}
            >
              <NavLink>Services</NavLink>
            </li>
            <li
              onClick={() => {
                navigate("/");
                scrollToSection("gallery");
              }}
            >
              <NavLink>Gallery</NavLink>
            </li>

            <li
              onClick={() => {
                navigate("/");
                scrollToSection("contact");
              }}
            >
              <NavLink>Contact</NavLink>
            </li>
            <li className="get-started">
              <NavLink to="/admin">Admin</NavLink>
              {/* <a href="login.html">Admin</a> */}
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
