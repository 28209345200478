import { Fragment, useEffect, useState } from "react";
import Header from "../../components/Home/Header";
import Footer from "../../components/Home/Footer";
import hoverimg from "../../assets/img/topdog.png";
import Button from "../../UI/Button/Button";
import useHttp from "../../hooks/use-http";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "../../UI/LoadingIndicator/LoadingIndicator";

const CompletePayment = (props) => {
  const navigate = useNavigate();
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [
    isCheckoutLoading,
    checkoutError,
    createCheckoutSession,
    checkoutData,
  ] = useHttp();
  const [isRegLoading, regError, getRegData, regData] = useHttp();

  const handlePayment = (data) => {
    if (data) {
      window.location.href = data.url;
    }
  };

  const fetchRegistrationData = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const regId = urlParams.get("regId");
    if (regId) {
      getRegData(
        {
          url: `${process.env.REACT_APP_BASE_API_URL}/website/registrations/getPaymentInfo?regId=${regId}`,
        },
        (data) => {
          data.regData.payment.status != "paid"
            ? setShowPaymentForm(true)
            : setShowPaymentForm(false);
        }
      );
    }
  };

  const handlePaymentRequest = async (event) => {
    event.preventDefault();
    try {
      createCheckoutSession(
        {
          url: `${process.env.REACT_APP_BASE_API_URL}/shop/payments/create-checkout-session`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            items: [
              {
                price_data: {
                  currency: "cad",
                  product_data: {
                    name: regData.serviceData.service_name,
                    description: regData.serviceData.service_desc,
                  },
                  unit_amount: regData.serviceData.price * 100,
                },
                quantity: 1,
              },
            ],
            regData: regData,
          },
        },
        handlePayment
      );
    } catch (err) {
      console.error("Error in fetch request:", err);
    }
  };

  useEffect(() => {
    fetchRegistrationData();
  }, []);

  return (
    <Fragment>
      <Header />
      <section id="contact" className="contact" style={{ paddingTop: "10%" }}>
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Complete Payment</h2>
          </div>
          <div
            style={{ textAlign: "center" }}
            className="col-lg-12 col-md-12"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            {showPaymentForm && (
              <div>
                <img src={hoverimg} alt="" style={{ height: 400 }} />

                <h4>{regData.serviceData.name}</h4>
                <h6>${regData.serviceData.price}</h6>

                <form>
                  <Button
                    title={"Complete Payment"}
                    onClick={handlePaymentRequest}
                  ></Button>
                </form>
              </div>
            )}{" "}
            {regError && <div>Registration ID not found</div>}
            {isRegLoading && <LoadingIndicator />}
            {!isRegLoading && !showPaymentForm && regData && (
              <p>Fee alredy paid for ID:{regData.regData.registrationId}</p>
            )}
          </div>
        </div>
      </section>

      <Footer />
    </Fragment>
  );
};

export default CompletePayment;
