import { Fragment } from "react";
import "bootstrap/dist/css/bootstrap.css";
import img1 from "../../assets/img/clubs/UKC.jpg";
import img2 from "../../assets/img/clubs/CKC.jpg";
import img3 from "../../assets/img/clubs/AKC.jpg";
import img4 from "../../assets/img/clubs/ABDT.jpg";
import { NavLink } from "react-router-dom";

const AboutUs = (props) => {
  return (
    <Fragment>
      <section id="about" className="about">
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>About Us</h2>
          </div>

          <div className="row content">
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="150">
              <p>
                <strong>Top Dog Training Academy</strong> has helped thousands
                of dogs and owners in the Yukon and across Canada remotely to
                achieve their training goals. Our primary focus is{" "}
                <strong>
                  obedience, socialization, behaviour modification and
                  aggression
                </strong>
                . We have the ability to solve complex behavioural issues and
                teach effective obedience through a{" "}
                <strong>balanced training approach</strong>. We firmly believe
                every dog is an individual and requires a training and/or
                behaviour modification program{" "}
                <strong>tailored to meet their unique needs.</strong>
              </p>
              <ul>
                <li>
                  <i className="ri-check-double-line"></i> Certified Dog trainer
                  in Whitehorse, Yukon
                </li>
                <li>
                  <i className="ri-check-double-line"></i> Certification
                  completed at the Academy of Dog Training in Abbotsford BC with
                  over 27 years’ experience.
                </li>
              </ul>
            </div>
            <div
              className="col-lg-6 pt-4 pt-lg-0"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <p>
                We pride ourselves on our ability to identify each{" "}
                <strong>canine client’s distinctive learning style </strong>and
                implementing balanced training and behaviour modification
                techniques to achieve behaviour goals.
                <strong>Top Dog Training Academy</strong> is proud to offer a{" "}
                <strong>full range of services including;</strong> comprehensive
                puppy program, group training classes, board & train program,
                socialization programs, private lessons, home visits, and online
                courses.
              </p>
              <NavLink className="btn-learn-more">Learn More</NavLink>
            </div>
          </div>
        </div>
      </section>
      <section className="team section-bg">
        <div className="section-title" data-aos="fade-up">
          <h2>Affiliations</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="member" data-aos="fade-up" data-aos-delay="100">
                <div className="member-img" style={{ textAlign: "center" }}>
                  <img
                    src={img1}
                    className="img-fluid"
                    style={{ maxHeight: 100 }}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="member" data-aos="fade-up" data-aos-delay="100">
                <div className="member-img">
                  <img
                    src={img2}
                    className="img-fluid"
                    style={{ maxHeight: 100 }}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 ">
              <div className="member" data-aos="fade-up" data-aos-delay="100">
                <div className="member-img">
                  <img
                    src={img3}
                    className="img-fluid"
                    style={{ maxHeight: 100 }}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 align-items-stretch">
              <div className="member" data-aos="fade-up" data-aos-delay="100">
                <div className="member-img">
                  <img
                    src={img4}
                    className="img-fluid"
                    style={{ maxHeight: 100 }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default AboutUs;
