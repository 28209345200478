import img1 from "../../assets/img/dogs/Jordy.jpg";
import img2 from "../../assets/img/dogs/Jeff.jpg";
import img3 from "../../assets/img/dogs/Jess.jpg";
import img4 from "../../assets/img/dogs/Jeff1.jpg";
import img5 from "../../assets/img/dogs/Jordy2.png";
import img6 from "../../assets/img/dogs/Jess1.jpg";
import img7 from "../../assets/img/dogs/Jeff2.jpg";
import img8 from "../../assets/img/dogs/Jordy1.png";
import {
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
const itemData = [
  { id: 1, img: img1 },
  { id: 2, img: img2 },
  { id: 3, img: img3 },
  { id: 4, img: img4 },
  { id: 5, img: img5 },
  { id: 6, img: img6 },
  { id: 7, img: img7 },
  { id: 8, img: img8 },
];

const OurDogs = (props) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.only("md"));

  const cols = isLargeScreen ? 4 : isMediumScreen ? 2 : 1;
  return (
    <section id="portfolio" className="portfolio">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Our Dogs</h2>
        </div>

        <div>
          <ImageList cols={cols}>
            {itemData.map((item) => (
              <ImageListItem key={item.img}>
                <img src={item.img} alt={item.title} loading="lazy" />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      </div>
    </section>
  );
};

export default OurDogs;
