import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { useEffect } from "react";
import Home from "./pages/Landing/Home";
import Dashboard from "./pages/Admin/Dashboard";
import AOS from "aos";
import "aos/dist/aos.css";
import Login from "./pages/Admin/Login";
import GenerateToken from "./pages/Admin/GenerateToken";
import Registrations from "./pages/Admin/Registrations";
import ContactRequests from "./pages/Admin/ContactRequests";
import RegistrationDetails from "./pages/Admin/RegistrationDetails";
import AuthenticateToken from "./pages/Admin/AuthenticateToken";
import CompletePayment from "./pages/Admin/CompletePayment";
import PostPayment from "./pages/Admin/PostPayment";
import NotFound from "./pages/Admin/NotFound";
import InitialRegistration from "./pages/Admin/InitialRegistration";
import ServiceDetails from "./pages/Admin/ServiceDetails/ServiceDetails";
import RegFormProvider from "./contexts/RegFormContext/RegFormProvider";
import ViewRegistrationDetails from "./pages/Admin/ViewRegistrationDetails/ViewRegistrationDetails";
import DataProvider from "./contexts/DataContext/DataProvider";
import SnackbarProvider from "./contexts/SnackbarContext/SnackbarProvider";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./contexts/AuthContext/AuthProvider";
import Team from "./pages/Admin/Team/TeamList";
import TeamMemberDetails from "./pages/Admin/Team/TeamMemberDetails";
import TeamDetails from "./pages/Landing/TeamDetails";
import ForgotPassword from "./pages/Admin/ForgotPassword";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <AuthProvider>
      <SnackbarProvider>
        <DataProvider>
          <RegFormProvider>
            <BrowserRouter>
              <Routes>
                <Route exact path="/" element={<PrivateRoute />}>
                  <Route path="/admin" element={<Dashboard />} />
                  <Route path="/generate-token" element={<GenerateToken />} />
                  <Route path="/registrations" element={<Registrations />} />
                  <Route
                    path="/contact-requests"
                    element={<ContactRequests />}
                  />
                  <Route
                    path="/view-registration-details"
                    element={<ViewRegistrationDetails />}
                  />
                  <Route path="/team" element={<Team />} />
                  <Route
                    path="/view-team-details"
                    element={<TeamMemberDetails />}
                  />
                </Route>
                <Route index element={<Home />} />
                <Route path="/" element={<Home />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/login" element={<Login />} />
                <Route
                  path="/initial-registration"
                  element={<InitialRegistration />}
                />

                <Route path="/service-details" element={<ServiceDetails />} />

                <Route
                  path="/registration-details"
                  element={<RegistrationDetails />}
                />

                <Route
                  path="/authenticate-token"
                  element={<AuthenticateToken />}
                />
                <Route path="/team-details" element={<TeamDetails />} />
                <Route path="/complete-payment" element={<CompletePayment />} />
                <Route path="/checkout" element={<PostPayment />} />

                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </RegFormProvider>
        </DataProvider>
      </SnackbarProvider>
    </AuthProvider>
  );
}

export default App;
