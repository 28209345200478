import { ArrowUpward } from "@mui/icons-material";
import "/Volumes/T7/Documents/VS Code Projects/topdogtraining/src/assets/vendor/icofont/icofont.min.css";
import styles from "./BackToTop.module.css";

const BackToTop = (props) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className={styles["floating-button"]} onClick={scrollToTop}>
      <div className={styles.fab}>
        <ArrowUpward sx={{ color: "white" }} />
      </div>
    </div>
  );
};

export default BackToTop;
