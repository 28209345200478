import { Fragment, useState } from "react";
import Header from "../../components/Home/Header";
import Footer from "../../components/Home/Footer";
import { useNavigate } from "react-router-dom";
import Button from "../../UI/Button/Button";
import Input from "../../UI/Input/Input";
import { requiredValidator } from "../../helpers/form-validators";

const AuthenticateToken = (props) => {
  const [regId, setRegId] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  const verifyTokenHandler = () => {
    navigate(`/registration-details?serviceId=${token}`);
  };
  const verifyRegIdHandler = () => {
    navigate(`/complete-payment?regId=${regId}`);
  };
  return (
    <Fragment>
      <Header />
      <section id="contact" className="contact" style={{ paddingTop: "10%" }}>
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Authenticate Token</h2>
            <p style={{ color: "red" }}>
              {" "}
              Kindly contact Jackie at jdnic07@gmail.com to get a token for new
              registration.
            </p>
          </div>
          <br />
          <br />
          <br />

          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="col-lg-6 col-md-6 center"
              data-aos="fade-up"
              data-aos-delay="300"
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "15px",
                boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.15)",
                width: "40%",
                margin: "5px",
              }}
            >
              <h4>Start New Registration</h4>

              <Input
                placeholder={"Enter Token"}
                onChange={(value, isValid) => {
                  if (isValid === true) {
                    setToken(value);
                  }
                }}
                validator={(value) => requiredValidator(value, "Required")}
              />
              <br />

              <Button title="Authenticate Token" onClick={verifyTokenHandler} />
            </div>
            <div
              className="col-lg-6 col-md-6 center"
              data-aos="fade-up"
              data-aos-delay="300"
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "15px",
                boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.15)",
                width: "40%",
                margin: "5px",
              }}
            >
              <h4>Complete Payment</h4>
              <Input
                placeholder={"Enter Registration ID"}
                onChange={(value, isValid) => {
                  if (isValid === true) {
                    setRegId(value);
                  }
                }}
                validator={(value) => requiredValidator(value, "Required")}
              />

              <br />

              <Button title="Verify" onClick={verifyRegIdHandler} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default AuthenticateToken;
