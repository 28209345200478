import { TextField } from "@mui/material";
import classes from "./Input.module.css";
import { useEffect, useState } from "react";

const Input = ({
  type,
  placeholder,
  label,
  id,
  isMultiline,
  onChange,
  validator,
  initialValue,
}) => {
  if (!initialValue) {
    //In case no inital value is supplied
    initialValue = "";
  }
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  const inputChangeHandler = (event) => {
    setValue(event.target.value);
    if (onChange) {
      const [isValid, errorText] = validator(event.target.value);
      onChange(event.target.value, isValid);
      setError(!isValid);
      setErrorMessage(errorText);
    }
  };
  return (
    <div className="form-group" style={{ margin: "10px" }}>
      <TextField
        inputProps={{
          style: {
            padding: 10,
          },
        }}
        className={classes.input + " form-control"}
        type={type}
        error={isError}
        id={id}
        value={value}
        multiline={isMultiline ? true : false}
        rows={6}
        label={label}
        helperText={errorMessage}
        placeholder={placeholder}
        onChange={inputChangeHandler}
      />
    </div>
  );
};

export default Input;
