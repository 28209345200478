import { useContext, useEffect } from "react";
import DataContext from "../../../contexts/DataContext/data-context";
import OurTeamItem from "./OurTeamItem";
import useHttp from "../../../hooks/use-http";

const OurTeam = (props) => {
  const dataContext = useContext(DataContext);
  const [isTeamDataLoading, teamDataError, getTeamData, teamData] = useHttp();

  useEffect(() => {
    if (dataContext.team.length < 1) {
      getTeamData(
        {
          url: `${process.env.REACT_APP_BASE_API_URL}/website/team/getAll?pageNumber=1`,
        },
        (data) => {
          dataContext.setTeam(data);
        }
      );
    }
  }, []);

  return (
    <section id="team" className="team section-bg">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Our Team</h2>
        </div>

        <div className="row">
          {dataContext.team.map((memberData) => (
            <OurTeamItem
              memberData={memberData}
              key={memberData.teamMemberId}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
