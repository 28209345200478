import { NavLink } from "react-router-dom";
import backgroundImage from "../../../assets/img/portfolio/portfolio-4.jpg";

const ServiceItem = (props) => {
  return (
    <div className="more-services">
      <div
        className="col-md-12 d-flex align-items-stretch mt-4"
        style={{ width: "90%", margin: "auto", maxWidth: "1300px" }}
      >
        <div
          className="card"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className="card-body">
            <h4 className="card-title">{props.data.service_name}</h4>
            <p>
              <strong>
                {props.data.service_time} • {props.data.service_price}
              </strong>
            </p>
            <p>{props.data.service_desc}</p>
            <div className="read-more">
              <NavLink to="/authenticate-token" className="btn-get-started">
                Registration form
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceItem;
