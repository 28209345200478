import { Fragment, useEffect } from "react";
import Header from "../../components/Home/Header";
import Footer from "../../components/Home/Footer";
import useHttp from "../../hooks/use-http";
import imgJackie from "../../assets/img/main.jpg";
import imgBritt from "../../assets/img/brittpic.jpg";
import imgAryanna from "../../assets/img/aryanna1.png";
import imgAndreas from "../../assets/img/andreas1.png";
import imgLauren from "../../assets/img/lauren1.png";
import imgJen from "../../assets/img/Jen1.jpeg";
import imgRach from "../../assets/img/rach2.png";
import imgAndre from "../../assets/img/andre.jpg";
import LoadingIndicator from "../../UI/LoadingIndicator/LoadingIndicator";
const images = {
  GcTj31vBzOZLlYfnU1SI: imgRach,
  IrxkW94AGye9y5FPqmsZ: imgAndreas,
  I8sOaPGv0zuBMSkt5drF: imgAryanna,
  JKHgjstS9TSSXGvX02F3: imgBritt,
  TnC7XT5uGU1ViEXdjY78: imgJackie,
  "5AnNmCew584FgUDM1rNN": imgJen,
  "0ysRaHTeFFajAw1rHqlB": imgLauren,
  U0QyQpsEbNmMM2IVAQ95: imgAndre,
};

const TeamDetails = (props) => {
  const [
    isTeamMemberDataLoading,
    teamMemberDataError,
    getTeamMemberDataData,
    teamMemberData,
  ] = useHttp();

  const fetchTeamMemberData = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const teamMemberId = urlParams.get("teamMemberId");
    if (teamMemberId) {
      getTeamMemberDataData(
        {
          url: `${process.env.REACT_APP_BASE_API_URL}/website/team/get?teamMemberId=${teamMemberId}`,
        },
        (data) => {}
      );
    }
  };

  useEffect(() => fetchTeamMemberData(), []);
  return teamMemberData ? (
    <Fragment>
      <Header />
      <section id="breadcrumbs" className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2 id="headName">{teamMemberData.name}</h2>
            <ol>
              <li id="title">{teamMemberData.title}</li>
            </ol>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-7 col-md-8">
                <div style={{ borderRadius: "10px" }}>
                  <img
                    src={images[teamMemberData.teamMemberId]}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      borderRadius: "10px",
                      boxShadow:
                        "0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07)",
                    }}
                    alt=""
                  />
                </div>
              </div>

              <div className="col-lg-5 col-md-4">
                <div
                  className="portfolio-info"
                  style={{
                    borderRadius: "10px",
                    padding: "10px 20px 10px 20px",
                    boxShadow: `0 1px 2px rgba(0,0,0,0.07),
                0 2px 4px rgba(0,0,0,0.07),
                0 4px 8px rgba(0,0,0,0.07),
                0 8px 16px rgba(0,0,0,0.07)`,
                  }}
                >
                  <h3 style={{ textAlign: "center" }}>Highlights</h3>
                  <br />
                  <p>{teamMemberData.high1}</p>
                  <p>{teamMemberData.high2}</p>
                  <p>{teamMemberData.high3}</p>
                  <p>{teamMemberData.high4}</p>
                  <p>{teamMemberData.high5}</p>
                  <p>{teamMemberData.high6}</p>
                  <p>{teamMemberData.high7}</p>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="col-lg-12">
              <div
                className="portfolio-description"
                style={{
                  borderRadius: "10px",
                  padding: "0.5px 20px 10px 20px",
                  boxShadow: `0 1px 2px rgba(0,0,0,0.07),
              0 2px 4px rgba(0,0,0,0.07),
              0 4px 8px rgba(0,0,0,0.07),
              0 8px 16px rgba(0,0,0,0.07)`,
                }}
              >
                <h3 style={{ textAlign: "center" }}>Profile</h3>
                <br />
                <p id="profile">{teamMemberData.profileText}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  ) : (
    <LoadingIndicator />
  );
};

export default TeamDetails;
