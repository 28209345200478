const Events = (props) => {
  return (
    <section id="events" className="team section-bg">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Events</h2>
        </div>

        <div className="row" style={{ margin: "auto", width: "80%" }}>
          <iframe
            title="cal"
            src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FWhitehorse&amp;src=dGhldG9wZG9ndHJhaW5pbmdhY2FkZW15QGdtYWlsLmNvbQ&amp;color=%23039BE5&amp;showCalendars=1&amp;showTabs=1&amp;showPrint=0&amp;showTitle=0&amp;showDate=1"
            style={{ border: "solid, 1px ,#777" }}
            width="800"
            height="600"
            frameBorder="0"
            scrolling="no"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Events;
