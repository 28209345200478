import {
  BarChartOutlined,
  BrushOutlined,
  CalendarTodayOutlined,
  DataObject,
  StoreOutlined,
  AddBoxRounded,
} from "@mui/icons-material";
import AdminItem from "../../components/Admin/AdminItem";
import AuthWrapper from "../../components/Home/AuthWrapper";
import Footer from "../../components/Home/Footer";
import AdminHeader from "../../components/Admin/AdminHeader";

const adminItems = [
  {
    id: 1,
    title: "Service Details",
    link: "/service-details",
    icon: <StoreOutlined sx={{ color: "#ffbb2c", fontSize: 40 }} />,
  },
  {
    id: 2,
    title: "Registrations",
    link: "/registrations",
    icon: <BarChartOutlined sx={{ color: "#5578ff", fontSize: 40 }} />,
  },
  {
    id: 3,
    title: "Contact Requests",
    link: "/contact-requests",
    icon: <CalendarTodayOutlined sx={{ color: "#e80368", fontSize: 40 }} />,
  },
  {
    id: 4,
    title: "Generate Token",
    link: "/generate-token",
    icon: <BrushOutlined sx={{ color: "#e361ff", fontSize: 40 }} />,
  },
  {
    id: 5,
    title: "Team",
    link: "/team",
    icon: <DataObject sx={{ color: "#47aeff", fontSize: 40 }} />,
  },
  // {
  //   id: 6,
  //   title: "Batches",
  //   link: "/batches",
  //   icon: <AddBoxRounded sx={{ color: "#47aeff", fontSize: 40 }} />,
  // },
];

const Dashboard = (props) => {
  return (
    <AuthWrapper>
      <AdminHeader />
      <div
        className="col-lg-12 col-md-12"
        data-aos="fade-up"
        data-aos-delay="300"
        style={{ height: 900 }}
      >
        <br />
        <br />
        <br />
        <section id="features" className="features">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Dashboard</h2>
              <p></p>
            </div>

            <div className="row" data-aos="fade-up" data-aos-delay="300">
              {adminItems.map((item) => (
                <AdminItem item={item} key={item.id} />
              ))}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </AuthWrapper>
  );
};

export default Dashboard;
