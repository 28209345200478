import classes from "./StatusButton.module.css";
const StatusButton = ({ title, onClick, color }) => {
  return (
    <div
      className={classes.status}
      onClick={onClick}
      style={{ backgroundColor: color, cursor: "pointer" }}
    >
      {title}
    </div>
  );
};

export default StatusButton;
