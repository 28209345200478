import Hero from "../../components/Home/Hero.js";
import Header from "../../components/Home/Header.js";
import AboutUs from "../../components/Home/AboutUs.js";
import Testimonials from "../../components/Home/Testimonials.js";
import Gallery from "../../components/Home/Gallery.js";
import Events from "../../components/Home/Events.js";
import OurDogs from "../../components/Home/OurDogs.js";
import Contact from "../../components/Home/Contact.js";
import Footer from "../../components/Home/Footer.js";
import Body from "../../UI/Body.js";
import BackToTop from "../../components/Home/BackToTop.js";
import OurTeam from "../../components/Home/OurTeam/OurTeam.js";
import Services from "../../components/Home/Services/Services.js";

const Home = (props) => {
  return (
    <Body>
      <Header />
      <Hero />
      <AboutUs />
      <Services />
      <Testimonials />
      <Gallery />
      <Events />
      <OurDogs />
      <OurTeam />
      <Contact />
      <Footer />
      <BackToTop />
    </Body>
  );
};

export default Home;
