import { Fragment, useContext, useEffect, useState } from "react";
import AdminHeader from "../../../components/Admin/AdminHeader";
import Footer from "../../../components/Home/Footer";
import { useNavigate } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import styles from "./TeamMemberDetails.module.css";
import Input from "../../../UI/Input/Input";
import Button from "../../../UI/Button/Button";
import DataContext from "../../../contexts/DataContext/data-context";
import { requiredValidator } from "../../../helpers/form-validators";
import SnackbarContext from "../../../contexts/SnackbarContext/snackbar-context";
import LoadingIndicator from "../../../UI/LoadingIndicator/LoadingIndicator";

const TeamMemberDetails = (props) => {
  const dataContext = useContext(DataContext);
  const snackbarContext = useContext(SnackbarContext);
  const intialTeamMemberData = {
    name: "",
    title: "",
    high1: "",
    high2: "",
    high3: "",
    high4: "",
    high5: "",
    high6: "",
    high7: "",
    profileText: "",
  };
  const [localTeamMemberData, setlocalTeamMemberData] =
    useState(intialTeamMemberData);
  const navigate = useNavigate();
  const [
    isTeamMemberLoading,
    teamMemberError,
    getTeamMemberData,
    teamMemberData,
  ] = useHttp();

  const fetchTeamMemberData = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const teamMemberId = urlParams.get("teamMemberId");
    if (teamMemberId) {
      getTeamMemberData(
        {
          url: `${process.env.REACT_APP_BASE_API_URL}/website/team/get?teamMemberId=${teamMemberId}`,
        },
        (data) => setlocalTeamMemberData(data)
      );
    }
  };
  useEffect(() => {
    fetchTeamMemberData();
  }, []);

  const renderHighlights = () => {
    let elements = [];
    for (let x = 0; x < 7; x++) {
      elements.push(
        <Input
          key={x}
          label={`Highlight ${x + 1}`}
          initialValue={
            localTeamMemberData[`high${x + 1}`]
              ? localTeamMemberData[`high${x + 1}`]
              : ""
          }
          validator={(value) => [true, ""]}
          onChange={(value, isValid) =>
            isValid
              ? setlocalTeamMemberData((prevData) => {
                  const tempData = { ...prevData };
                  tempData[`high${x + 1}`] = value;
                  return tempData;
                })
              : null
          }
        />
      );
    }
    return elements;
  };

  const [
    isTeamMemberUpdateLoading,
    teamMemberUpdateError,
    updateTeamMemberData,
    teamMemberUpdateData,
  ] = useHttp();
  const [isTeamDataLoading, regsitrationsDataError, getTeamData, teamData] =
    useHttp();
  const updateTeamMember = () => {
    updateTeamMemberData(
      {
        url: `${process.env.REACT_APP_BASE_API_URL}/website/team/update?teamMemberId=${localTeamMemberData.teamMemberId}`,
        body: localTeamMemberData,
        headers: { "Content-Type": "application/json" },
        method: "POST",
      },
      (data) => {
        snackbarContext.showSnackbar("success", "Team Member data updated!");
        getTeamData(
          { url: `${process.env.REACT_APP_BASE_API_URL}/website/team/getAll` },
          (data) => dataContext.setTeam(data)
        );
      }
    );
  };

  return (
    <Fragment>
      <AdminHeader />
      <section id="contact" className="contact" style={{ paddingTop: "10%" }}>
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Team Member Details</h2>
          </div>

          <div className="row">
            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div>
                {(isTeamDataLoading || isTeamMemberUpdateLoading) && (
                  <LoadingIndicator />
                )}
              </div>
              <Input
                label={"Name"}
                initialValue={localTeamMemberData.name}
                validator={(value) => requiredValidator(value, "Required")}
                onChange={(value, isValid) =>
                  isValid
                    ? setlocalTeamMemberData((prevData) => {
                        return { ...prevData, name: value };
                      })
                    : null
                }
              />
              <Input
                label={"Title"}
                initialValue={localTeamMemberData.title}
                validator={(value) => requiredValidator(value, "Required")}
                onChange={(value, isValid) =>
                  isValid
                    ? setlocalTeamMemberData((prevData) => {
                        return { ...prevData, title: value };
                      })
                    : null
                }
              />
              {renderHighlights()}
              <Input
                label={"Profile"}
                initialValue={localTeamMemberData.profileText}
                isMultiline={true}
                validator={(value) => requiredValidator(value, "Required")}
                onChange={(value, isValid) =>
                  isValid
                    ? setlocalTeamMemberData((prevData) => {
                        return { ...prevData, profileText: value };
                      })
                    : null
                }
              />
            </div>
            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <Button title={"Update"} onClick={updateTeamMember} />
              <Button title={"Back"} onClick={() => navigate("/team")} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default TeamMemberDetails;
